.mc-faqs-container {
  margin: 28px 0;

  @media only screen and (max-width: 992px) {
    margin: 0;
    padding: 0 4px;
  }

  .mc-accordeon-container {
    position: relative;
    margin: 14px 0px;
    border-bottom: 1px solid var(--Secundarios-Neutro-1, #040303);

    &.active {
  
      .mc-accordeon-toggle-icon {
        transform: rotate(-180deg) !important;
        top: 13px !important;
      }
    }
  
    .mc-accordeon-toggle {
      display: block;
      position: relative;
      cursor: pointer;
      padding: 16px 14px;
      padding-right: 58px;
    
      color: var(--Contenidos, #131112);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 137.5% */

      @media only screen and (max-width: 992px) {
        padding: 16px 0;
        padding-right: 20px;
      }
  
      &:hover{
        opacity: 0.8;
      }
    }
    
    .mc-accordeon-toggle-icon {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 16px;
      top: 17px;
      -webkit-transition: all .2s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .2s cubic-bezier(.77,0,.175,1);
      -o-transition: all .2s cubic-bezier(.77,0,.175,1);
      transition: all .2s cubic-bezier(.77,0,.175,1);

      @media only screen and (max-width: 992px) {
        right: 0;
      }
    
      svg {
        width: 100%;
        height: auto;
      }
    }
    
    .mc-accordeon-content {
      padding-bottom: 22px;
      padding-top: 0px;
      text-align: left;
      color: var(--Contenidos, #131112);
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }
  }

  &.mc-faqs-container-type-1 {

  }
  &.mc-faqs-container-type-2 {

  }
}