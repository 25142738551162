.mc-footer-container {
  position: relative;
  background-color: $primaryColor;
  padding-bottom: 70px;

  @media only screen and (min-width: 992px) {
    padding-bottom: 0px;
  }

  &__cards {
    margin: 0 auto;
    margin-bottom: -28px;
    width: 180px;
    display: block;
    position: relative;
    top: -32px;
  }

  &__bg {
    padding-bottom: 30px;
  }

  &__section {
    padding: 6px 0px;

    &--links {
      text-align: center;
      margin-bottom: 0px;
      position: relative;
      padding: 0px;
      margin: 20px auto;
      max-width: 380px;

      a {
        color: var(--Text-Inverted-Hg-Emphasis, #FFF);
        font-family: "Myriad Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        display: inline-block;
        width: 50%;
        margin: 0px;
        position: relative;

        &:nth-child(2) {
          &::before {
            content: "";
            width: 1px;
            height: 15px;
            background-color: #ED1C27;
            position: absolute;
            left: 4px;
            top: 0px;
            bottom: 0px;
            margin: auto;
          }
        }
      }
    }

    .links-text {
      margin: 28px 0;

      p {
        color: var(--Text-Inverted-Hg-Emphasis, #FFF);
        text-align: center;
        font-family: "Myriad Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }

  &__subbg {
    background-color: $primaryColorDark;
    padding: 30px 0px;
    text-align: center;

    @media only screen and (min-width: 992px) {
      padding: 20px 0px;
    }

    img {
      width: 80%;
      height: auto;
      max-width: 270px;

      @media only screen and (min-width: 992px) {
        max-width: 220px;
      }
    }
  }

  &__image {
    width: 200px;
    height: auto;
    display: block;
    margin: 24px auto;
  }

  &__text {
    color: var(--Text-Inverted-Md-Emphasis, #CCC);
    /* CAP/c-Regular */
    font-family: "Myriad Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    max-width: 750px;
    margin: auto;

    @media only screen and (max-width: 992px) {
      max-width: fit-content;
    }

    p {
      padding: 0 40px;
      text-align: left;

      @media only screen and (min-width: 992px) {
        padding: 0 0px;
        text-align: justify;
      }
    }

    a {
      color: var(--Text-Inverted-Md-Emphasis, #CCC);
      /* CAP/c-Regular */
      font-family: "Myriad Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      text-decoration: underline;
    }
  }

  &__imgs {
    display: flex;
    gap: 70px;
    justify-content: center;
    margin: 24px 0;
    align-items: center;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 28px;
      margin: 42px 0;
    }
  }

  hr {
    border: none;
    height: 1px;
    background: var(--Secundarios-Neutro-7, #CCC);
    width: 35%;
    margin: 0 auto;

    @media only screen and (max-width: 992px) {
      width: 90%;
    }
  }

  .mc-footer-vigilated {
    position: absolute;
    left: 20px;
    bottom: 0px;
    width: 18px;
    z-index: 1;
    height: auto;
    -webkit-filter: invert(100%);
    filter: invert(100%);

    @media only screen and (min-width: 992px) {
      left: -30px;
      position: absolute;
      bottom: 0px;
    }
  }

  &.mc-footer-container-type-2 {
    .mc-footer-container__cards {
      position: relative;
      top: auto;
      margin-bottom: 0px;
    }

    .mc-footer-container__bg {
      padding: 30px 0px;
      text-align: center;

      &--image {
        width: 100%;
        height: auto;
        max-width: 400px;
        margin-bottom: 8px;

        @media only screen and (min-width: 992px) {
          position: absolute;
          left: 20px;
          max-width: 250px;
        }
      }

      .mc-footer-container__sections {
        text-align: left;

        @media only screen and (min-width: 992px) {
          display: block;
          padding-left: 380px;
          box-sizing: border-box;
        }

        &--section {
          margin-bottom: 36px;

          h4 {
            margin-bottom: 10px;
            color: #fff;
          }

          ul {
            margin: 0px;

            a {
              display: block;
              width: 100%;
              text-align: left;
              font-size: 16px;
              line-height: 22px;
              text-decoration: none;
              cursor: pointer;
              margin-bottom: 10px;

              &:hover {
                text-decoration: underline;
                opacity: 0.7;
              }

              &::before {
                display: none !important;
              }
            }
          }

          p {
            margin-top: 0px;
            color: #fff;
          }

          @media only screen and (min-width: 992px) {
            position: relative;
            margin-bottom: 22px;

            h4 {
              position: absolute;
              top: 0px;
              margin: 0px !important;
            }

            ul,
            p {
              display: block;
              max-width: 100%;
              text-align: left;
              padding-left: 130px;

              a {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                margin-right: 20px;
              }
            }
          }
        }
      }

      .mc-footer-container__image {
        text-align: left;
        display: block;
        margin: 20px 0px;
        margin-bottom: 6px;

        @media only screen and (min-width: 992px) {
          margin-top: -28px;
        }
      }
    }

    .mc-footer-vigilated {
      @media only screen and (min-width: 992px) {
        bottom: 0px;
      }
    }
  }

  &.mc-footer-container-type-3 {
    .mc-footer-container__bg {
      padding-top: 50px;

      @media only screen and (min-width: 992px) {
        position: relative;
      }
    }

    .mc-footer-container__cards {
      width: 200px;
      max-width: 350px;
      top: 0px;
      margin-bottom: 28px;
    }

    .mc-footer-container__bg--image {
      display: block;
      margin: 20px auto;
      width: 250px;

      @media only screen and (min-width: 992px) {
        margin-top: 0px;
        position: relative;
        left: 8%;
      }
    }

    .mc-footer-container__image {
      margin-bottom: 0px;

      @media only screen and (min-width: 992px) {
        position: absolute;
        right: 20px;
        top: 0px;
        margin-top: 0px;
        width: 160px;
      }
    }

    .mc-footer-container__section--links {
      margin: 28px auto;
      margin-top: 0px;

      @media only screen and (min-width: 992px) {
        a:nth-child(2)::before {
          left: -18px;
        }
      }
    }

    .mc-footer-container__section {
      border: 0px !important;

      @media only screen and (min-width: 992px) {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        padding-top: 0px;

        .mc-footer-container__section--links {
          text-align: left;
          width: 50%;
          position: absolute;
          left: 20px;
          top: 0px;
        }
      }
    }

    .mc-footer-container__subbg {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background-color: transparent !important;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      margin-top: 40px;
      color: #fff;
    }

    .mc-footer-container__text {
      @media only screen and (min-width: 992px) {
        padding-top: 35px;
      }
    }

    .mc-footer-container__cards {
      @media only screen and (min-width: 992px) {
        display: none !important;
      }
    }
  }

  &.mc-footer-container-type-4 {
    padding: var(--Size-5XL, 40px) var(--Size-3XL, 20px) 80px
      var(--Size-3XL, 20px);

    @media only screen and (min-width: 992px) {
      padding: var(--Size-6XL, 48px) 141px var(--Size-2XL, 24px) 80px;
    }

    .mc-footer-container__section--links a:nth-child(2)::before {
      height: 24px;
      @media only screen and (min-width: 992px) {
        left: -10%;
      }
    }

    .footer_container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      position: relative;
      @media only screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
        gap: 0px 40px;
        flex-wrap: wrap;
        color: white;
        max-width: 1280px;
        padding-left: 70px;
      }

      .mc-footer-vigilated {
        position: absolute;
        height: 195px;
        left: 0;
        top: 0;
        z-index: 3;
        @media only screen and (max-width: 992px) {
          top: auto;
          bottom: 225px;
          left: -1.2%;
        }
      }

      .logos_container {
        order: 4;
        display: flex;
        justify-content: center;
        @media only screen and (min-width: 992px) {
          order: 1;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          width: 30%;
        }

        .mc-footer-container__bg--image {
          width: 246px;
          height: 32px;
        }
      }

      .buttons_container {
        margin: 0;
        padding: 0;
        order: 1;
        @media only screen and (min-width: 992px) {
          order: 2;
          width: 60%;
          height: 23px;
          margin: 0;
          padding: 0;

          .mc-footer-container__section--links {
            margin: 0;
            @media only screen and (min-width: 992px) {
              text-align: left;
              margin: 0;
            }
          }
        }
      }

      .contacts_container {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        order: 6;
        @media only screen and (min-width: 992px) {
          order: 3;
          display: flex;
          justify-content: flex-start;
          margin: 42px 0 0 0;
          padding: 0;
          width: 30%;
          height: 20%;
        }
        .contacts {
          margin: 0;
          padding: 0;
          display: flex;
          gap: 24px;
          @media only screen and (min-width: 992px){
            display: flex;
            flex-direction: column;
            width: 160px;
          }
          .contact1,
          .contact2 {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;
            color: white;
            .award {
              width: 20px;
              height: 20px;
            }
            .title {
              font-weight: 700;
            }
            .content {
              font-weight: 400;
              margin: 0;
              padding: 0;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration-line: underline;
              text-decoration-style: solid;
              text-decoration-skip-ink: none;
              text-decoration-thickness: auto;
              text-underline-offset: auto;
            }
          }
        }
      }

      .text_container {
        order: 3;
        margin: 0;
        @media only screen and (min-width: 992px) {
          order: 4;
          margin: 32px 0px 0 0;
          padding: 0;
          width: 60%;
        }

        .text {
          /* Caption/C1-Regular */
          color: white;
          font-family: var(--Family-DINPro, DINPro);
          font-size: var(--Size-Xs, 12px);
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }

      .icons {
        order: 2;
        display: flex;
        justify-content: center;
        @media only screen and (min-width: 992px) {
          order: 5;
          display: flex;
          justify-content: flex-start;
          margin: 0;
          padding: 0;
          width: 30%;
        }

        .mc-footer-container__image {
          padding: 0;
          margin: 0;
          width: 161px;
          height: 49px;
          padding-bottom: 10%;
        }
      }

      .closing_container {
        order: 7;
        border-top: 1px solid white;
        padding-top: 30px;
        @media only screen and (min-width: 992px) {
          padding: 0;
          margin: 0;
          order: 6;
          border-top: 1px solid white;
          padding-top: 20px;
          display: flex;
          justify-content: center;
          text-align: center;
          display: flex;
          flex-basis: 100%;
        }

        .closing {
          color: white;
          text-align: center;
          font-size: 14px;
          @media only screen and (min-width: 992px) {
            /* C/REGULAR */
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  &.mc-footer-container-type-5 {
    margin-top: 60px;

    .mc-footer-container__cards {
      position: relative;
      top: -48px;
      margin-bottom: 0px;
      width: 210px;
    }

    .mc-footer-container__bg {
      padding: 30px 0px;
      padding-top: 0px;
      margin-top: -38px;
      text-align: center;

      .mc-app-container:nth-child(1) {
        .mc-footer-container__sections {
          text-align: center;
          padding: 0 40px;

          @media only screen and (min-width: 992px) {
            padding: 0;
            padding-left: 48%;
          }

          .mc-footer-container__sections--section {
            h4 {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 20px;

              @media only screen and (min-width: 992px) {
                position: relative;
                text-align: left;
                margin-bottom: 28px !important;
              }
            }

            ul {
              @media only screen and (min-width: 992px) {
                padding: 0px;
              }

              a {
                text-align: center;
                margin-bottom: 20px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 350;

                @media only screen and (min-width: 992px) {
                  margin-bottom: 16px;
                }
              }
            }

            p {
              font-weight: 350;

              @media only screen and (min-width: 992px) {
                padding: 0px;
                margin-bottom: 16px;
              }
            }

            &:last-child {
              margin-bottom: 0px;
            }

            @media only screen and (min-width: 992px) {
              display: inline-block;
              width: 50%;
              vertical-align: top;
            }
          }
        }
      }

      &--image {
        width: 100%;
        height: auto;
        max-width: 400px;
        margin-bottom: 8px;

        @media only screen and (min-width: 992px) {
          position: absolute;
          left: 20px;
          max-width: 345px;
        }
      }

      .mc-footer-container__sections {
        text-align: left;

        @media only screen and (min-width: 992px) {
          display: block;
          padding-left: 380px;
          box-sizing: border-box;
        }

        &--section {
          margin-bottom: 36px;

          h4 {
            margin-bottom: 10px;
            color: #fff;
          }

          ul {
            margin: 0px;

            a {
              display: block;
              width: 100%;
              text-align: left;
              font-size: 16px;
              line-height: 22px;
              text-decoration: none;
              cursor: pointer;
              margin-bottom: 10px;

              &:hover {
                text-decoration: underline;
                opacity: 0.7;
              }

              &::before {
                display: none !important;
              }
            }
          }

          p {
            margin-top: 0px;
            color: #fff;
          }

          @media only screen and (min-width: 992px) {
            position: relative;
            margin-bottom: 22px;

            h4 {
              position: absolute;
              top: 0px;
              margin: 0px !important;
            }

            ul,
            p {
              display: block;
              max-width: 100%;
              text-align: left;
              padding-left: 130px;

              a {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                margin-right: 20px;
              }
            }
          }
        }
      }

      .mc-footer-container__image {
        text-align: left;
        display: block;
        margin: 20px 0px;
        margin-bottom: 6px;

        @media only screen and (min-width: 992px) {
          margin-top: -28px;
        }
      }

      .mc-footer-container__text {
        & > div {
          border-top: 1px solid #fff;
        }

        p {
          padding: 0 0px;
          text-align: justify;
          font-size: 16px;
          line-height: 22px;
          font-weight: 350;
        }
      }
    }

    .mc-footer-vigilated {
      -webkit-filter: invert(0%);
      filter: invert(0%);
      top: -292px;
      bottom: auto;
      width: 26px;

      @media only screen and (min-width: 992px) {
        bottom: 0px;
        top: auto;
        left: -40px;
      }
    }
  }

  .vigilado {
    position: absolute;
    top: 100px;
    left: 205px;

    @media only screen and (max-width: 992px) {
      top: 292px;
      left: 20px;;
    }
  }
}

@media (max-width: 200px) {
}
