.mc-page {
  position: relative;
  padding: 60px 0 70px;
  background: var(--Red-gradient, linear-gradient(180deg, var(--primario-primario-7-base, #ED1C27) 0%, #FF000F 82%));

  &__title--container {
    padding: 32px 72px;
    background: #fff;

    h1 {
      margin: 0 0 5px 96px;

      .mc-page__title {
        color: var(--Text-High-Emphasis, #333);
        font-family: "Myriad Pro Bold";
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;

        @media only screen and (max-width: 992px) {
          color: var(--primario-primario-7-base, #ED1C27);
          /* Title/h1-Black */
          font-family: "Myriad Pro";
          font-size: 48px;
          font-style: normal;
          font-weight: 900;
          line-height: 50px; /* 104.167% */
        }
      }

      .mc-page__title--red {
        color: var(--primario-primario-7-base, #ED1C27);
        /* Title/h1-Black */
        font-family: "Myriad Pro Black";
        font-size: 48px;
        font-style: normal;
        font-weight: 900;
        line-height: 50px; /* 104.167% */
      }

      @media only screen and (max-width: 992px) {
        margin: 0 0 8px 0;
        max-width: 97%;
      }
    }

    .mc-page__subtitle {
      color: var(--Secundarios-Neutro-4, #333);
      font-family: "Myriad Pro";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 96px;

      strong {
        color: var(--Secundarios-Neutro-4, #333);
        /* Title/h6-Regular */
        font-family: "Myriad Pro";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      @media only screen and (max-width: 992px) {
        margin-left: 0;
      }
    }

    .mc-page__subtitle-date {
      color: var(--Secundarios-Neutro-4, #333);
      font-family: "Myriad Pro";
      font-style: normal;
      font-weight: 400;
      margin-left: 96px;
      font-size: 16px;
      line-height: 22px; /* 137.5% */
    }

    @media only screen and (max-width: 992px) {
      padding: 32px;
    }
  }

  &.dashboard {
    background-color: rgb(240, 240, 240);
    padding-top: 90px;

    .mc-app-container {
      width: 1110px;
      padding: 0 15px;
    }
  }

  &.terms,
  &.faqs {
    h1 {
      @media only screen and (max-width: 992px) {
      }
    }
  }

  .mc-static-content {
    margin: 30px 0;

    @media only screen and (min-width: 992px) {
      margin: 50px 0;
    }
  
  }
}