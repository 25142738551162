.mc-section-testForm {
  background: #fff;
  .mc-test-questions {
    position: relative;
    text-align: center;
    padding: 34px 0px;

    @media only screen and (min-width: 992px) {
      padding: 50px 0px;
      text-align: center;
      background-color: #fff;
      width: 622px;
      margin: 0 auto;
      padding-bottom: 90px;
    }

    h4 {
      color: var(--Text-High-Emphasis, #333);
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 120% */
      margin-top: 0px;

      @media only screen and (min-width: 992px) {

      }
    }

    .mc-app-container div {
      @media only screen and (min-width: 992px) {
        display: inline-block;
        vertical-align: middle;
        width: 226px;

        &.mc-test-questions--details {
          width: 100%;
          margin: 0;

          p {
            text-align: left;
            width: 100%;
          }
          textarea {
            width: 100% !important;
            height: 90px;
            &.disabled{
              cursor: not-allowed;
              opacity: 0.4;
            }
          }
        }
      }
    }

    .mc-test-questions--buttons {

      button {
        padding-left: 30px;
        position: relative;
        svg {
          position: absolute;
          margin-left: -32px;
          top: 10px;
        }

        &:hover {
          background: var(--Primario-Primario-3, #FFC5C8);
          opacity: 1 !important;
        }
        &.active{
          background: var(--Primario-Primario-3, #FFC5C8);
          opacity: 1 !important;
        }
      }
      @media only screen and (min-width: 992px) {
        width: 400px !important;
      }
    }

    button {
      border-radius: 8px;
      text-align: center;
      font-family: "Myriad Pro Semibold";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */

      width: 140px;
      height: 42px;
      display: inline-block;
      vertical-align: middle;
      margin: 0px 6px;
      cursor: pointer;

      @media only screen and (min-width: 992px) {
        width: 180px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    button.button-quiz {
      background: #fff;
    }

    &--details {
      position: relative;
      height: 0px;
      margin-top: 18px;
      p {
        color: var(--Text-High-Emphasis, #333);
        font-family: "Myriad Pro Semibold";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
        width: 100%;
        font-weight: 300;
        margin: 16px auto;
        text-align: left;
        margin-bottom: 8px;
        font-weight: bold;
        display: none;
      }
      textarea {
        display: block;
        width: 100%;
        border-radius: 8px;
        resize: none;
        height: 86px;
        box-sizing: border-box;
        padding: 11px 14px;
        outline: 0px;

        border-radius: 6px;
        border: 2px solid var(--Secundarios-Neutro-4, #333);
        /* Shadows/shadow50 */
        box-shadow: 0px 0px 1px 0px rgba(27, 29, 34, 0.02), 0px 1px 1px 0px rgba(27, 29, 34, 0.05);
        background: var(--Background-Background-Lowest, #FFF);

        color: var(--Text-Placeholder, #707070);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: none;
      }
      .mc-button-container {
        margin: 16px 0px;

        button {
          width: 100%;
          margin: 0px;
        }
      }
      .mc-test-questions--live {
        text-align: left;
        color: var(--Text-Medium-Emphasis, #707070);
        font-family: "Myriad Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        margin: 6px 0px;
        display: none;
      }
      &.active {
        height: auto;

        p, textarea, .mc-test-questions--live {
          display: block;
        }
      }
      &.loading {
        .mc-button-container {
          button {
            background-color: #A41018 !important;
          }
        }
      }
    }
  }
}