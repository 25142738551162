.mc-progressBar-container {
  background: #0000;
  background-color: #f0f0f0;
  border-radius: 20px;
  height: 8px;
  margin: 7px 0 0 47px;
  position: relative;

  .mc-progressBar-progress {
    background: #ed1c27;
    border-radius: 4px;
    bottom: 0;
    box-shadow: 0 0 var(--m, 16px) 0 #ed1c27;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 0;
    z-index: 1;

    span {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $secondaryColor;
      border-radius: 4px;
      right: -4px;
      top: -2px;
      z-index: 1;
    }

    &__character {
      height: 52px;
      left: 0;
      position: absolute;
      top: -22px;
      width: 52px;
      z-index: 2;

      img {
        height: auto;
        left: -20px;
        position: relative;
        width: 100%;
      }
    }
    
  }

  .mc-progressBar-percent {
    color: var(--Text-High-Emphasis, #333);
    font-family: "Myriad Pro Black";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px; /* 137.5% */
    left: -47px;
    position: absolute;
    text-align: center;
    top: -7px;
    z-index: 2;

    &.white {
      color: #fff;
    }
  }

  .mc-progressBar-bubble {
    position: absolute;
    width: 38px;
    height: auto;
    bottom: 2px;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
      position: relative;
      left: -19px;
    }
  }

  .mc-progressBar-title {
    position: absolute;
    left: 0px;
    top: -26px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.5;
  }

  .mc-progressBar-label {
    position: absolute;
    right: 0px;
    top: -25px;
    font-size: 13px;
  }

  &.has-title {
    margin-top: 25px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &.col-2 {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}