.mc-page {
    &.awards {
        .tabs-container {
            padding: 32px 18px;
            display: flex;
            justify-content: center;
    
            .section-tab-login {
              border-radius: 118.357px;
              background: var(--Secundarios-Neutro-9, #FFF);
              width: 100%;
              padding: 8px;
              max-width: 400px;
    
                .tab {
                    display: flex;
                    align-items: center;
        
                    .tab-item {
                        cursor: pointer;
                        padding: 7.101px 20px;
                        min-width: 84px;
            
                        &.active {
                            border-radius: 71.014px;
                            border: 2px solid var(--Primario-Primario-8, #C70E18);
                            box-shadow: 0px 85px 24px 0px rgba(235, 15, 15, 0.00), 0px 54px 22px 0px rgba(235, 15, 15, 0.01), 0px 31px 18px 0px rgba(235, 15, 15, 0.05), 0px 14px 14px 0px rgba(235, 15, 15, 0.09), 0px 3px 7px 0px rgba(235, 15, 15, 0.10);
                        }
            
                        h3 {
                            align-items: center;
                            display: flex;
                            color: var(--Text-High-Emphasis, #333);
                            text-align: center;
                            font-family: "Myriad Pro";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px;
                            gap: 5px;
                            justify-content: center;
                            margin: 0;
                        }

                        @media only screen and (max-width: 992px) {
                            min-width: 65px;
                        }
                    }

                    @media only screen and (max-width: 992px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }

        .awards-container {
            border-radius: 20px;
            background: var(--Secundarios-Neutro-8, #F0F0F0);
            /* Shadow card red */
            box-shadow: 0px 258px 72px 0px rgba(0, 0, 0, 0.00), 0px 165px 66px 0px rgba(0, 0, 0, 0.01), 0px 93px 56px 0px rgba(0, 0, 0, 0.05), 0px 41px 41px 0px rgba(0, 0, 0, 0.09), 0px 10px 23px 0px rgba(0, 0, 0, 0.10);
            padding: 24px 16px;

            &.type-2 {
                @media only screen and (max-width: 992px) {
                    background-color: #fff;
                    overflow: hidden;
                }
            }

            .awards-title-container {
                display: flex;
                gap: 24px;
                justify-content: center;

                @media only screen and (max-width: 992px) {
                    flex-direction: column;
                    text-align: center;
                }

                .awards-title {
                    max-width: 530px;

                    @media only screen and (max-width: 992px) {
                        margin: auto;
                    }
                    

                    h5{
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px;
                        margin: 0;
                        margin-bottom: 8px;
                    }

                    span {
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        max-width: 513px;
                    }
                }
            }

            .awards-cards-container {
                margin-top: 24px;
                border-radius: 12px;
                background: var(--Secundarios-Neutro-9, #FFF);
                box-shadow: 0px 45px 13px 0px rgba(0, 0, 0, 0.00), 0px 29px 12px 0px rgba(0, 0, 0, 0.01), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
                padding: 16px 0 0px 12px;

                &.type-2 {
                    padding: 16px 12px 0;
                    @media only screen and (max-width: 992px) {
                        padding: 0;
                        box-shadow: none;
                    }
                }

                h6 {
                    align-items: center;
                    display: flex;
                    color: var(--Text-High-Emphasis, #333);
                    font-family: "Myriad Pro";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 137.5% */
                    gap: 10px;
                    margin: 0;
                    justify-content: center;

                    @media only screen and (max-width: 992px) {
                        flex-direction: column;
                        text-align: center;
                    }

                    strong {
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                    }
                }

                h6.type-2 {
                    display: block;
                    img {
                        margin: 8px 0 4px;
                    }
                }

                .awards-cards {
                    h6 {
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-align: center;
                        margin: 16px auto 9px;
                    }

                    .slick-slider {
                        .slick-list {

                          .slick-track {
                            padding-bottom: 70px;

                            .slick-slide {
                                width: 250px !important;
                                margin-right: 12px;
                                min-height: 364px;
      
                                .awards-cards__items--item {
                                    border-radius: 20px;
                                    border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                                    background: var(--Secundarios-Neutro-9, #FFF);
                                    box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                                    max-width: 280px;
        
                                    img {
                                        border-radius: 20px 20px 0px 0px;
                                        width: 100%;
                                        height: 251px;
                                    }
      
                                    .awards-cards__items--item--desc {
                                        padding: 16px;

                                        .destination-container {
                                            margin-bottom: 8px;

                                            .destination {
                                                color: var(--primario-primario-7-base, #ED1C27);
                                                font-family: "Myriad Pro Black";
                                                font-size: 20px;
                                                font-style: normal;
                                                font-weight: 900;
                                                line-height: normal;

                                                .small {
                                                    color: var(--Primario-Primario, #ED1C27);
                                                    font-family: "Myriad Pro";
                                                    font-size: 16px;
                                                    font-style: normal;
                                                    font-weight: 900;
                                                    line-height: 22px; /* 137.5% */
                                                }
                                            }
                                        }

                                        .days {
                                            color: var(--Contenidos, #131112);
                                            font-family: "Myriad Pro BoldSemiCn";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 22px; /* 137.5% */
                                        }

                                        .button-container {
                                            margin: 24px auto 16px;
                                            width: fit-content;
                                        }
                                    }
                                }
                            }
                          }
                        }
      
                        .slick-dots {
                          bottom: 32px;
        
                          li {
                            background-color: #707070;
                            border-radius: 50px;
                            height: 8px;
                            margin: 0 4px;
                            position: relative;
                            transition: all .3scubic-bezier(.77,0,.175,1);
                            width: 8px;
        
                            button {
                              width: 100%;
                              height: 100%;
        
                              &::before {
                                display: none;
                              }
                            }
        
                            &.slick-active {
                              background-color: #333;
                              width: 20px;
                              height: 8px;
                              border-radius: 20px;
                            }
                          }
                        }
        
                        .slick-arrow {
                          top: auto;
                          bottom: 28px;
                          transform: translate(0px, 0px);
                          margin: auto;
                          background-position: center center !important;
                          background-repeat: no-repeat !important;
                          background-size: 100% !important;
                          opacity: 1;
                          z-index: 2;
                    
                          &:before {
                            display: none;
                          }
                    
                          &.slick-prev {
                            right: 80px;
                            background-image: url('../../../public/assets/images/arrow-L.png');
                            -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                            transition: all .3s cubic-bezier(.77,0,.175,1);
                          }
                    
                          &.slick-next {
                            left: 80px;
                            background-image: url('../../../public/assets/images/arrow-L.png');
                            transform: rotate(180deg);
                            -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                            transition: all .3s cubic-bezier(.77,0,.175,1);
                          }
                        }
                    }
                }
            }

            .awards-cards-container {
                .awards-bonos {
                    display: flex;
                    justify-content: center;
                    gap: 16px;

                    @media only screen and (max-width: 992px) {
                        flex-direction: column;
                        text-align: center;
                    }

                    .awards-cards__items--item {
                        border-radius: 20px;
                        border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                        background: var(--Secundarios-Neutro-9, #FFF);
                        box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                        max-width: 33%;
                        padding: 16px 32px;
                        width: 33%;

                        @media only screen and (max-width: 992px) {
                            max-width: 100%;
                            width: auto;
                        }

                        .awards-cards__items--item--desc {
                            h6 {
                                align-items: center;
                                color: var(--Text-High-Emphasis, #333);
                                font-family: "Myriad Pro Semibold";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px; /* 137.5% */
                                gap: 10px;
                                margin: 0;
                                justify-content: center;
                                text-align: center;
            
                                strong {
                                    color: var(--Text-High-Emphasis, #333);
                                    font-family: "Myriad Pro Black";
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 900;
                                    line-height: normal;
                                }

                                small {
                                    color: var(--Text-High-Emphasis, #333);
                                    font-family: "Myriad Pro Black";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 900;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }

                .comercios-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    padding: 16px 0 32px;

                    @media only screen and (max-width: 992px) {
                        flex-direction: column;
                        text-align: center;
                    }

                    span {
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .comercios-imgs {

                        &.type-2{
                            width: 705px;
                            @media only screen and (max-width: 992px) {
                                width: 100%;
                            }
                        }

                        @media only screen and (max-width: 992px) {
                            width: 100%;
                            justify-content: center;
                        }

                        img {
                            width: 85px;
                            border-radius: 12.339px;
                            box-shadow: 0px 34.703px 10.025px 0px rgba(0, 0, 0, 0.00), 0px 22.364px 9.254px 0px rgba(0, 0, 0, 0.01), 0px 12.339px 7.712px 0px rgba(0, 0, 0, 0.05), 0px 5.398px 5.398px 0px rgba(0, 0, 0, 0.09), 0px 1.542px 3.085px 0px rgba(0, 0, 0, 0.10);
                            height: auto;
                            height: 88px;
                            margin: 0 9.5px;
                        }

                        .slick-track {
                            display: flex;
                            gap: 8px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 992px) {
            .tabs-container {
                padding-top: 32px;
      
                .section-tab-login {
                  width: fit-content;
                  max-width: fit-content;
      
                    .tab {
                        gap: 13.019px;
      
                        .tab-item {
                            min-width: 56px;

                            &:hover {
                                background: var(--Primario-Primario-3, #FFC5C8);
                                border-radius: 71.014px;
                            }
        
                            &.active {
                                &:hover {
                                background: #fff;
                                border-radius: 71.014px;
                                border: 2px solid var(--Primario-Primario-8, #C70E18);
                                box-shadow: 0px 85px 24px 0px rgba(235, 15, 15, 0.00), 0px 54px 22px 0px rgba(235, 15, 15, 0.01), 0px 31px 18px 0px rgba(235, 15, 15, 0.05), 0px 14px 14px 0px rgba(235, 15, 15, 0.09), 0px 3px 7px 0px rgba(235, 15, 15, 0.10);
                                }
                            }
        
                            h3 {
                                align-items: center;
                                display: flex;
                                color: var(--Text-High-Emphasis, #333);
                                text-align: center;
                                font-family: "Myriad Pro";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px;
                                gap: 5px;
                                justify-content: center;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .awards-container {
                padding: 24px 45px;
                .awards-cards-container {
                    padding: 32px 0 0 24px;
                    .awards-cards {
                        .slick-slider {
                            .slick-list {
                                padding-left: 0;

                                .slick-track {
                                    justify-content: center;
                                    display: flex;
                                    flex-direction: row;
                                    max-width: max-content;
                                    gap: 12px;
                                
                                    .slick-slide {
                                        width: 250px !important;
                                        margin: 0;
                                        min-height: 415px;
                
                                        .awards-cards__items--item {
                                            border-radius: 20px;
                                            border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                                            background: var(--Secundarios-Neutro-9, #FFF);
                                            box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                                            max-width: 280px;
                
                                            img {
                                                border-radius: 20px 20px 0px 0px;
                                                width: 100%;
                                            }
                
                                            .awards-cards__items--item--desc {
                                                padding: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}