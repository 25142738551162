.mc-button-container {
  display: block;
  width: 100%;
  text-align: center;

  button {
    display: block;
    width: 100%;
    border: 0px;
    height: 45px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    cursor: pointer;
    border-radius: 8px;

    .inline-loader {
      width: 30px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__primary {
    button {
      background-color: #ED1C27;
      color: #fff;

      &:hover {
        background-color: #A41018;
      }
      &.mc-button-container__status--inactive {
        background-color: #707070;
        cursor: no-drop;
        color: #CCC;
      }
    }
  }

  &__secondary {
    button {
      background-color: #fff;
      color: var(--Text-High-Emphasis, #333);
      /* P1/p-Semibold */
      font-family: "Myriad Pro Semibold";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      border-radius: var(--s, 8px);

      &:hover {
        background: var(--Primario-Primario-3, #FFC5C8);
      }
      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }

  &__outline {
    button {
      background-color: #fff;
      border: 1px solid var(--Davivienda-Neutro-040303, #040303);
      color: var(--Crossborder-secundario-040303, #040303);
      text-align: center;
      font-family: "Myriad Pro Semibold";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */

      &:hover {
        opacity: 0.6;
      }
      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }

  &__link {
    button {
      background-color: transparent;
      color: #333;
      border: none;
      text-decoration: underline;
      font-family: "Myriad Pro Semibold";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      height: auto;

      &:hover {
        border: none;
      }
    }
  }
}