.sectionAwards {
  padding: 32px 0;

  @media only screen and (max-width: 992px) {
    padding: 32px 0 56px;
  }
}

.sectionRedemptions {
  background: linear-gradient(93deg, #fff -.23%, #f0f0f0 63.57%);
  border: 1px solid #f0f0f0;
  border-radius: 24px;
  margin: 0 auto;
  height: 280px;
  max-width: 90%;
  position: relative;
  width: 1100px;
  text-align: center;

  @media only screen and (max-width: 992px) {
    height: 395px;
    max-width: 100%;
    width: auto;
  }

  .mc-page-content__redemptionsOnAwards--labels {
    display: inline-block;
    vertical-align: top;
    width: 300px;
    margin-top: 4%;
    text-align: center;

    @media only screen and (max-width: 992px) {
      display: block;
      width: auto;
      margin-top: 0;
    }

    h3,
    p {
      text-align: left;
      padding-right: 30px;

      @media only screen and (max-width: 992px) {
        padding-right: 0;
        text-align: center;
      }
    }

    p {
      color: var(--Secundarios-Neutro-4, #333);
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      text-align: center;
      margin: 12px 0 0;

      @media only screen and (max-width: 992px) {
        max-width: 250px;
        margin: 0 auto;
      }
    }

    h3 {
      color: var(--Text-High-Emphasis, #333);
      font-family: "Myriad Pro Semibold";
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */
      margin-top: 46px;
      text-align: center;
      margin-bottom: 12px;

      @media only screen and (max-width: 992px) {
        margin-top: 1em;
      }
    }
  }

  .mc-page-content__redemptionsOnAwards--awards {
    display: inline-block;
    vertical-align: top;
    width: 634px;
    margin-top: 6%;

    @media only screen and (max-width: 992px) {
      margin-top: 10px;
      width: 100%;
      width: 392px;
    }

    &.empty {
      @media only screen and (max-width: 992px) {
        min-height: 195px;
        position: relative;
        text-align: center;
        height: auto;
        width: auto;
        margin-top: 14px;
  
        img {
          width: 100%;
        }
      }
    }

    .mc-awards-container {
      .mc-awards-column-2 {
        .mc-awards-container__items {
          .mc-awards-container__items--item {
            .type-3 {
              width: 100%;
            }
          }
        }
      }
    }
    
  }

  &.lg-1,
  &.lg-0 {
    @media only screen and (max-width: 992px) {
      height: auto;
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;
    }
    .mc-page-content__redemptionsOnAwards--awards  {
      width: 440px;
      margin-top: 40px;

      @media only screen and (max-width: 992px) {
        width: 100%;
        margin-top: 10px;
      }
      .mc-awards-container__items--item {
        width: 80%;

        @media only screen and (max-width: 992px) {
          width: 100%;
        }
      }
    }
  }
  &.lg-2 {
    @media only screen and (max-width: 992px) {
      height: auto;
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;
    }
    .mc-page-content__redemptionsOnAwards--awards  {
      width: 634px;

      @media only screen and (max-width: 992px) {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.mc-awards-container {
  border-radius: 20px;
  background: var(--Secundarios-Neutro-9, #FFF);
  /* Shadow card red */
  box-shadow: 0px 258px 72px 0px rgba(0, 0, 0, 0.00), 0px 165px 66px 0px rgba(0, 0, 0, 0.01), 0px 93px 56px 0px rgba(0, 0, 0, 0.05), 0px 41px 41px 0px rgba(0, 0, 0, 0.09), 0px 10px 23px 0px rgba(0, 0, 0, 0.10);
  padding: 24px 50px;

  @media only screen and (max-width: 992px) {
    padding: 24px 20px;
  }

  .mc-awards-container__items {
    display: block;
    margin-bottom: 20px;

    .mc-awards-container__items--item {
      display: block;
      width: 100%;
      margin-bottom: 22px;
      box-sizing: border-box;
      background-color: rgb(236, 236, 236);
      padding: 18px;
      position: relative;
      box-shadow: 0px 258px 72px 0px rgba(0, 0, 0, 0.00), 0px 165px 66px 0px rgba(0, 0, 0, 0.01), 0px 93px 56px 0px rgba(0, 0, 0, 0.05), 0px 41px 41px 0px rgba(0, 0, 0, 0.09), 0px 10px 23px 0px rgba(0, 0, 0, 0.10);

      &__image {
        display: block;
        background-color: #fff;
        margin-bottom: 20px;
        padding: 15px 0;

        img {
          display: block;
          margin: 0 auto;
          width: 80px;
          height: auto;
        }
      }
      &__info {
        display: block;
        width: 100%;
      }
      &__name {
        color: var(--Text-High-Emphasis, #333);
        font-family: "Myriad Pro";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 116.667% */
      }
      &__price {
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        a {
          color: $colorText;
        }
      }

      &.type-1 {
        @media only screen and (min-width: 992px) {
          width: 30.3%;
          height: auto;
        }

        .mc-awards-container__items--item__container {
          display: flex;
          gap: 16px;
          flex-direction: column;
          @media only screen and (min-width: 992px){
            flex-direction: row;
          }

          .mc-awards-container__items--item__image {
            margin: 0;
            padding: 0 10px;
            display: flex;
            align-items: center;

            img {
              border-radius: 10px;
            }
          }

          .mc-awards-container__items--item__description {
            p {
              margin: 0;
              font-size: var(--Size-Md, 12px);
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 137.5% */
              padding-bottom: 10px;
            }
          }

          .slow_ani.mc-button-container__status--inactive {
            border-radius: var(--Size-Xs, 4px);
            background:#000;
          }
        }
      }
      &.type-2 {
        height: auto;

        .mc-awards-container__items--item__info {
          display: flex;
          height: 100px;
          margin-bottom: 18px;
          gap: 13px;

          .mc-awards-container__items--item__image {
            width: 100px;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: 1;
            padding: 0px;
            box-sizing: border-box;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: 70% !important;
          }
          .mc-awards-container__items--item__info2{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .mc-awards-container__items--item__info {
            height: 100%;
            padding-left: 116px;
            box-sizing: border-box;
          }

          .mc-awards-container__items--item__name{
            font-size: 20px;
          }

          .mc-awards-container__items--item__price {
            background-color: $primaryColor;
            color: #fff;
            font-size: 2em !important;
            width: 100%;
            font-style: normal;
            font-weight: 700;
            line-height: 44px;
            text-align: center;
          }
        }

        p {
          text-align: center;
        }
      }
      &.type-3 {
        height: auto;
        padding: 0px;
        border-radius: 20px;

        .mc-awards-container__items--item__info {
          display: block;
          width: 100%;
          box-sizing: border-box;
          height: 128px;
          padding: 20px 20px 20px 113px;
          position: relative;
          background: #fff;
          border-radius: 20px 20px 0 0;

          &.download {
            margin-bottom: 15px;
          }

          .mc-awards-container__items--item__image {
            background-position: 50% !important;
            background-repeat: no-repeat !important;
            background-size: 94% !important;
            border-radius: 30px;
            box-sizing: border-box;
            height: 100px;
            left: 7px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            top: 14px;
            width: 100px;
            z-index: 1;
          }
          .mc-awards-container__items--item__labels {
            box-sizing: border-box;
            height: 100%;
            z-index: 2;

            .mc-awards-container__items--item__price {
              border-radius: 100px;
              border: 1px solid var(--Secundarios-Neutro-8, #F0F0F0);
              background: var(--Secundarios-Neutro-9, #FFF);
              box-shadow: 0px 33px 9px 0px rgba(0, 0, 0, 0.00), 0px 21px 9px 0px rgba(0, 0, 0, 0.01), 0px 12px 7px 0px rgba(0, 0, 0, 0.05), 0px 5px 5px 0px rgba(0, 0, 0, 0.09), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
              color: var(--primario-primario-7-base, #ED1C27);
              font-family: "Myriad Pro Black";
              font-size: 28px;
              font-style: normal;
              font-weight: 900;
              line-height: 32px; /* 114.286% */
              padding: 4px 0;
              text-align: center;
              margin-top: 12px;
            }
          }
        }

        .mc-awards-container__items--item__actions {
          padding: 12px;
          padding-top: 10px;
          box-sizing: border-box;
          background: #fff;
          border-radius: 0 0 20px 20px;
          margin-top: -32px;

          p {
            color: var(--Text-Medium-Emphasis, #707070);
            text-align: center;
            font-family: "Myriad Pro";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            margin: 16px;
          }
        }
      }
      &.type-4 {
        height: auto;
        background-color: transparent !important;
        box-sizing: border-box;
        padding-left: 60px;
        position: relative;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;

        .mc-awards-container__items--item__info {
          background-color: #ededed;
          box-sizing: border-box;
          padding: 14px;
          padding-bottom: 10px;
          padding-left: 55px;

          .mc-awards-container__items--item__image {
            position: absolute;
            width: 100px;
            height: 100px;
            box-sizing: border-box;
            border-radius: 200px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            left: 0px;
            z-index: 2;
            background: #fff;
            border: 1px solid #000;
            -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

            span {
              position: absolute;
              left: 12px;
              top: 12px;
              bottom: 12px;
              right: 12px;
              z-index: 1;
              border-radius: 100px;
              background-position: center center !important;
              background-repeat: no-repeat !important;
              background-size: cover !important;
            }
          }

          .mc-awards-container__items--item__name {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 6px;
          }

          .mc-awards-container__items--item__price {
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 28px;
            margin-bottom: 10px;
          }

          a {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            text-decoration-line: underline;
          }
        }
      }
    }
  }

  .mc-awards-container__actions {
    text-align: center;
    margin: 18px 0px;
    max-width: 390px;
    margin: 18px auto;

    a {
      background-color: $primaryColor;
      color: #fff;
      text-decoration: none;
      display: block;
      padding: 10px 0;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
    }
  }

  &.mc-awards-column-1 {
    .mc-awards-container__items {
      @media only screen and (min-width: 992px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin: 0 auto;
          margin-bottom: 28px;
        }
      }
    }
  }

  &.mc-awards-column-2 {
    border-radius: 0;
    background: var(--Secundarios-Neutro-9, #FFF);
    /* Shadow card red */
    box-shadow: none;
    padding: 0;
    background: transparent;
    .mc-awards-container__items {
      @media only screen and (min-width: 992px) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 47%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }

  &.mc-awards-column-3 {
    .mc-awards-container__items {
      @media only screen and (min-width: 992px) {
        margin-left: -1.5%;
        margin-right: -1.5%;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 30.3%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }
}

.mc-awards-container__steper {
  margin: 28px 0;
  padding-top: 60px;
  .slick-slider {
    .slick-track,
    .slick-list {
      .mc-awards-container__items {
        .mc-awards-container__items--item {
          .mc-custom-modal {
            &.slow_ani,
            .slow_ani {
              -webkit-transition: all 0.1s cubic-bezier(0.77, 0, 0.175, 1) !important;
              -moz-transition: all 0.1s cubic-bezier(0.77, 0, 0.175, 1) !important;
              -o-transition: all 0.1s cubic-bezier(0.77, 0, 0.175, 1) !important;
              transition: all 0.1s cubic-bezier(0.77, 0, 0.175, 1) !important;
            }
          }
        }
      }
    }

    .slick-dots {
      position: absolute;
      top: -60px;
      bottom: auto;
      background-color: rgb(245, 245, 245);
      height: 38px;

      @media only screen and (min-width: 992px) {
        right: 0px;
        left: 0px;
        width: 350px;
        margin: auto;
      }

      li {
        width: 50%;
        height: 100%;
        float: left;
        margin: 0px !important;
        text-align: center;
        font-weight: 600;
        line-height: 4px;
        -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1) !important;
        -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1) !important;
        -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1) !important;
        transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1) !important;

        &.slick-active {
          background-color: $primaryColor;
          color: #fff;
        }

        @media only screen and (min-width: 992px) {
          span {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 1;
          }
        }
      }
    }
  }
}

.mc-awards-container__selector {
  position: relative;
  margin-bottom: 32px;
}
