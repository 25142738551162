.mc-page {
    &.progress {
        padding: 60px 0 0;
        min-height: 55vh;
        .progress-container {
            padding: 56px 75px;
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 992px) {
                padding: 56px 20px;
                flex-direction: column;
                gap: 32px;
            }

            &.active {
                align-items: flex-start;
                justify-content: center;
            }
            
            .puntaje-container {
                border-radius: 20px;
                background: var(--Secundarios-Neutro-8, #F0F0F0);
                /* Shadow card red */
                box-shadow: 0px 258px 72px 0px rgba(0, 0, 0, 0.00), 0px 165px 66px 0px rgba(0, 0, 0, 0.01), 0px 93px 56px 0px rgba(0, 0, 0, 0.05), 0px 41px 41px 0px rgba(0, 0, 0, 0.09), 0px 10px 23px 0px rgba(0, 0, 0, 0.10);
                min-width: 350px;

                @media only screen and (max-width: 992px) {
                    width: 100%;
                }

                .puntaje-title {
                    border-radius: 20px 20px 0px 0px;
                    border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                    background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                    padding: 13px 16px;

                    svg {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    h4 {
                        align-items: center;
                        display: flex;
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro Semibold";
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px; /* 114.286% */
                        gap: 10px;
                        margin: 0;
                    }
                }

                .puntaje-desc {
                    padding: 20px 12px 24px;

                    @media only screen and (max-width: 992px) {
                        padding: 20px 15px 24px;
                    }

                    .profile-container {
                        display: flex;
                        gap: 16px;
                        align-items: center;
                        padding-right: 50px;

                        @media only screen and (max-width: 992px) {
                            padding-right: 0;
                        }

                        img {
                            width: 110px;
                            height: 110px;
                        }

                        .puntaje-points-container {
                            p {
                                margin: 0;
                            }
                            .points {
                                color: var(--primario-primario-7-base, #ED1C27);
                                font-family: "Myriad Pro Bold";
                                font-size: 64px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                            }

                            .points-text {
                                color: var(--Text-High-Emphasis, #333);
                                font-family: "Myriad Pro";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px; /* 137.5% */
                                margin-top: -6px;
                                margin-left: 4px;
                            }
                        }
                    }

                    .actividades-container {
                        margin: 20px 0;

                        .toggle {
                            position: relative;
                            border-bottom: 1px solid var(--Secundarios-Neutro-4, #333);
                            .toggle-icon{
                                position: absolute;
                                width: 28px;
                                height: 28px;
                                right: 21px;
                                top: 17px;
                                transition: all 0.2s cubic-bezier(0.77, 0, 0.175, 1);

                                svg {
                                    border-radius: 100px;
                                    background: var(--primario-primario-7-base, #ED1C27);
                                    padding: 4px;
                                    transition: all .3s cubic-bezier(.77,0,.175,1);
                                }
                            }

                            .toggle-text {
                                color: var(--Text-High-Emphasis, #333);
                                font-family: "Myriad Pro";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px; /* 137.5% */
                                padding: 8px 16px;
                                max-width: 250px;
                            }
                        }

                        .mc-accordeon-content {
                            
                            .actividades-item {
                                position: relative;
                                border-bottom: 1px solid var(--Secundarios-Neutro-4, #333);
                                margin-top: 10px;
                                padding: 8px 16px;

                                &__points {
                                    color: var(--Text-High-Emphasis, #333);
                                    font-family: "Myriad Pro";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 900;
                                    line-height: 22px; /* 137.5% */
                                    max-width: 150px;
                                    padding: 8px 0;
                                }

                                &__desc {
                                    color: var(--Text-High-Emphasis, #333);
                                    /* CAP/c-Semibold */
                                    font-family: "Myriad Pro Semibold";
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 16px; /* 133.333% */
                                    position: absolute;
                                    right: 16px;
                                    top: 30%;
                                    text-align: right;

                                    span {
                                        color: var(--Text-Medium-Emphasis, #707070);
                                        /* CAP/c-Regular */
                                        font-family: "Myriad Pro";
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 16px; /* 133.333% */
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }

                    .actualizacion-container {
                        max-width: 180px;
                        text-align: center;
                        margin: auto;

                        span {
                            color: var(--Text-Medium-Emphasis, #707070);
                            text-align: center;
                            /* CAP/c-Semibold */
                            font-family: "Myriad Pro";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 16px; /* 133.333% */
                        }
                    }
                }
            }

            .misiones-status-container {
                display: flex;
                flex-direction: column;
                gap: 16px;

                @media only screen and (max-width: 992px) {
                    width: 100%;
                }

                .misiones-container {
                    border-radius: 20px;
                    background: var(--Secundarios-Neutro-8, #F0F0F0);
                    /* Shadow card red */
                    box-shadow: 0px 258px 72px 0px rgba(0, 0, 0, 0.00), 0px 165px 66px 0px rgba(0, 0, 0, 0.01), 0px 93px 56px 0px rgba(0, 0, 0, 0.05), 0px 41px 41px 0px rgba(0, 0, 0, 0.09), 0px 10px 23px 0px rgba(0, 0, 0, 0.10);
                    min-width: 750px;

                    @media only screen and (max-width: 992px) {
                        width: 100%;
                        min-width: 350px;
                    }

                    .misiones-title {
                        border-radius: 20px 20px 20px 20px;
                        border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                        background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                        padding: 13px 16px;

                        @media only screen and (max-width: 992px) {
                            height: 150px;
                        }
    
                        svg {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            border-radius: 0 0 0 20px;
                        }
    
                        h4 {
                            align-items: flex-start;
                            display: flex;
                            color: var(--Text-High-Emphasis, #333);
                            font-family: "Myriad Pro Semibold";
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32px; /* 114.286% */
                            gap: 10px;
                            margin: 0;
                            flex-direction: column;

                            &.close {
                                @media only screen and (max-width: 992px) {
                                    bottom: -31px;
    
                                    &.type-1 {
                                        bottom: -66px;
                                    }
                                }
                            }

                            
                        }

                        .available {
                            position: absolute;
                            top: 0;
                            right: 16px;
                            border-radius: var(--Size-2XL, 24px);
                            border: 1px solid var(--primario-primario-7-base, #ED1C27);
                            background: var(--Secundarios-Neutro-9, #FFF);
                            padding: 4px 8px;
                            color: var(--primario-primario-7-base, #ED1C27);
                            text-align: center;
                            font-family: "Myriad Pro";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 157.143% */
                            width: fit-content;
                            margin: 13px 0 0;
                        }

                        .toggle {
                            position: relative;

                            .toggle-icon{
                                position: absolute;
                                width: 28px;
                                height: 28px;
                                right: 16px;
                                top: 0;
                                transition: all 0.2s cubic-bezier(0.77, 0, 0.175, 1);

                                &.close {
                                    @media only screen and (max-width: 992px) {
                                        top: 25px;
    
                                        &.type-1 {
                                            top: 60px
                                        }
                                    }
                                }

                                &.open {
                                    @media only screen and (max-width: 992px) {
                                        top: -9px;
                                    }
                                }

                                svg {
                                    border-radius: 100px;
                                    background: var(--primario-primario-7-base, #ED1C27);
                                    padding: 5px;
                                    transition: all .3s cubic-bezier(.77,0,.175,1);
                                }
                            }

                            .toggle-text {
                                color: var(--Text-High-Emphasis, #333);
                                font-family: "Myriad Pro";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px; /* 137.5% */
                                padding: 8px 16px;
                                max-width: 250px;
                            }
                        }

                        &.active {
                            border-radius: 20px 20px 0px 0px;

                            svg {
                                border-radius: 0;
                            }

                            h4 {
                                flex-direction: row;
                                align-items: center;
                            }

                            @media only screen and (max-width: 992px) {
                               height: auto;
                            }
                        }
                    }

                    .misiones-desc {
                        padding: 42px 66px 24px 55.64px;
                        display: flex;
                        gap:56px;
                        flex-direction: column;

                        &.type-2 {
                            padding: 24px 66px 24px 55.64px;

                            @media only screen and (max-width: 992px) {
                                padding: 24px;
                            }
                        }

                        @media only screen and (max-width: 992px) {
                            padding: 42px 15px 24px;
                            gap: 80px
                        }

                        .despegue-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 24px;

                            &.type-2 {
                                align-items: flex-start;

                                @media only screen and (max-width: 992px) {
                                    align-items: center;
                                }
                            }

                            @media only screen and (max-width: 992px) {
                                flex-direction: column;
                                gap:24px;
                            }

                            .img-container {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                align-items: center;
                                gap: 24px;

                                &.type-2 {
                                    flex-direction: row;
                                    justify-content: center;
                                    gap: 24px;

                                    @media only screen and (max-width: 992px) {
                                        flex-direction: column-reverse;
                                    }

                                    .title-container {
                                        margin-right: 5px;

                                        &.type-2 {
                                            margin-right: 15px;
                                            margin-left: 24px;
                                        }
                                    }
                                }

                                &.type-3 {
                                    flex-direction: row;
                                    align-items: center;
                                    flex: 1;

                                    @media only screen and (max-width: 992px) {
                                        flex-direction: column;
                                    }

                                    .title-container {
                                        margin-left: 15px;
                                        margin-right: 38px;

                                        &.type-2 {
                                            margin-right: 15px;
                                            margin-left: 24px;
                                        }
                                    }
                                }

                                &.type-4 {
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-around;
                                    margin-top: 20px;
                                }

                                &.type-5 {
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-top: 20px;
                                }

                                .title-container {
                                    text-align: center;
                                    .misionName {
                                        color: var(--Text-High-Emphasis, #333);
                                        text-align: center;
                                        font-family: "Myriad Pro";
                                        font-size: 28px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 32px; /* 114.286% */
                                        margin: 0 0 8px;
                                    }

                                    .points {
                                        border-radius: 100px;
                                        background: linear-gradient(90deg, #A9A9A9 -7.71%, #FFF 50.23%, #A9A9A9 108.18%);
                                        padding: 4px 12px;
                                        color: var(--Secundarios-Neutro-4, #333);
                                        text-align: center;
                                        font-family: "Myriad Pro";
                                        font-size: 24px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 28px; /* 116.667% */
                                        max-width: fit-content;
                                        margin: 0 auto 4px;

                                        &.type-2 {
                                            border-radius: 0;
                                            background: transparent;
                                            padding: 0;
                                            color: var(--Text-High-Emphasis, #333);
                                            /* Title/h4-Semibold */
                                            font-family: "Myriad Pro Semibold";
                                            font-size: 28px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 32px; /* 114.286% */
                                        }
                                    }

                                    .info {
                                        color: var(--Text-High-Emphasis, #333);
                                        text-align: center;
                                        font-family: "Myriad Pro";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 22px; /* 137.5% */
                                        margin: 0 auto;
                                        max-width: 257px;

                                        &.type-2 {
                                            color: var(--Text-Medium-Emphasis, #707070);
                                            /* P1/p-Regular */
                                            font-family: "Myriad Pro";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 22px; /* 137.5% */
                                        }

                                        &.type-3 {
                                            color: var(--Text-High-Emphasis, #333);
                                            /* P1/p-Semibold */
                                            font-family: "Myriad Pro Semibold";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 22px; /* 137.5% */
                                            text-align: left;
                                            max-width: 179px;

                                            a {
                                                color: var(--Text-High-Emphasis, #333);
                                                /* P1/p-Semibold */
                                                font-family: "Myriad Pro Semibold";
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 22px;
                                                text-decoration-line: underline;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                    
                                    .pista {
                                        border-radius: 8px;
                                        border: 1px solid var(--primario-primario-7-base, #ED1C27);
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;
                                        padding: 8px;
                                        margin-top: 20px;
                                        text-align: left;
                                        max-width: 267px;
                                        margin: 20px auto 0;

                                        @media only screen and (max-width: 992px) {
                                            max-width: 310px;
                                        }

                                        p {
                                            color: var(--Text-High-Emphasis, #333);
                                            /* P1/p-Regular */
                                            font-family: "Myriad Pro";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 22px;

                                            strong {
                                                color: var(--Text-High-Emphasis, #333);
                                                /* P1/p-Semibold */
                                                font-family: "Myriad Pro Semibold";
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 22px; /* 137.5% */
                                            }
                                        }
                                    }

                                    .desc {
                                        color: var(--Text-High-Emphasis, #333);
                                        text-align: center;
                                        /* P1/p-Semibold */
                                        font-family: "Myriad Pro";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 22px; /* 137.5% */
                                        margin: 20px auto 0;
                                        max-width: 292px;

                                        &.type-2 {
                                            color: var(--Text-High-Emphasis, #333);
                                            text-align: center;
                                            /* P1/p-Semibold */
                                            font-family: "Myriad Pro Semibold";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 22px; /* 137.5% */
                                        }

                                        strong {
                                            color: var(--Text-High-Emphasis, #333);
                                            /* P1/p-Black */
                                            font-family: "Myriad Pro Black";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 900;
                                            line-height: 22px;
                                        }
                                    }

                                    .button-container {
                                        width: 297px;
                                        margin-top: 24px;
                                    }
                                }
                            }

                            .info-pos {
                                border-radius: 16px 16px 0px 0px;
                                background: var(--Secundarios-Neutro-4, #333);
                                padding: 4px 8px;
                                max-width: 238px;
                                margin: auto;
                                text-align: center;

                                span {
                                    color: var(--Text-Inverted-Hg-Emphasis, #FFF);
                                    text-align: center;
                                    font-family: "Myriad Pro";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 16px; /* 114.286% */
                                }
                            }

                            .barInfo-container {
                                display: flex;
                                flex-direction: column;
                                gap: 24px;

                                @media only screen and (max-width: 992px) {
                                    width: 100%;
                                    min-width: 310px;
                                }

                                .barInfo {
                                    background-color: #fff;
                                    width: 320px;
                                    z-index: 1;
                                    border: 2px solid var(--Secundarios-Neutro-9, #FFF);
                                    border-radius: 16px;
                                    box-sizing: border-box;
                                    margin: 0;
                                    padding: 16px;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    @media only screen and (max-width: 992px) {
                                        width: 100%;
                                    }

                                    .barInfo--head {
                                        border-radius: 20px;
                                        border: 1px solid var(--primario-primario-7-base, #ED1C27);
                                        display: inline-block;
                                        color: var(--Text-High-Emphasis, #333);
                                        font-family: "Myriad Pro";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 22px; /* 137.5% */
                                        height: 20px;
                                        margin-bottom: 7px;
                                        padding: 2px 14px 4px;
                                        position: relative;
                                        width: fit-content;

                                        .mc-icon {
                                            display: inline-block;
                                            margin-right: 2px;
                                            position: relative;
                                            top: -1px;
                                            vertical-align: middle;
                                        }
                                    }

                                    .barInfo--row {
                                        span {
                                            color: var(--Text-High-Emphasis, #333);
                                            font-family: "Myriad Pro";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 22px; /* 137.5% */

                                            strong {
                                                color: var(--Text-High-Emphasis, #333);
                                                font-family: "Myriad Pro";
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 900;
                                                line-height: 22px; /* 137.5% */
                                            }
                                        }
                                        span:nth-child(2) {
                                            position: absolute;
                                            right: 4px;
                                            top: 0;
                                        }

                                    }
                                }
                            }

                            .wallets-container {
                                border-radius: 20px;
                                border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                                background: var(--Secundarios-Neutro-9, #FFF);
                                box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                                padding: 24px 16px;
                                height: fit-content;
                                margin-top: 15px;
                                min-width: 282px;

                                @media only screen and (max-width: 992px) {
                                    min-width: 292px;
                                }

                                &.type-2 {
                                    background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                                    box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                                    padding: 16px;
                                    margin-top: 0;
                                }

                                .title {
                                    color: var(--Text-High-Emphasis, #333);
                                    text-align: center;
                                    font-family: "Myriad Pro BoldSemiCn";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 137.5% */
                                    max-width: 291px;
                                    margin: 0 auto;
                                }

                                .tabs-container {
                                    padding: 0;
                                    display: flex;
                                    justify-content: center;
                            
                                    .section-tab-login {
                                        border-radius: 118.357px;
                                        border: 1.184px solid var(--Secundarios-Neutro-6, #939393);
                                        background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                                        width: 100%;
                                        padding: 4px;
                                        max-width: 262px;
                                        margin: 20px auto;
                            
                                        .tab {
                                            display: flex;
                                            align-items: center;
                                
                                            .tab-item {
                                                cursor: pointer;
                                                padding: 7.101px 20px;
                                                min-width: 84px;
                                                text-align: center;
                                    
                                                &.active {
                                                    border-radius: 71.014px;
                                                    border: 2px solid var(--Primario-Primario-8, #C70E18);
                                                    box-shadow: 0px 85px 24px 0px rgba(235, 15, 15, 0.00), 0px 54px 22px 0px rgba(235, 15, 15, 0.01), 0px 31px 18px 0px rgba(235, 15, 15, 0.05), 0px 14px 14px 0px rgba(235, 15, 15, 0.09), 0px 3px 7px 0px rgba(235, 15, 15, 0.10);
                                                }
                                    
                                                h3 {
                                                    align-items: center;
                                                    display: flex;
                                                    color: var(--Text-High-Emphasis, #333);
                                                    text-align: center;
                                                    font-family: "Myriad Pro";
                                                    font-size: 16px;
                                                    font-style: normal;
                                                    font-weight: 600;
                                                    line-height: 22px;
                                                    gap: 5px;
                                                    justify-content: center;
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }

                                .instrucciones {
                                    max-width: 310px;

                                    ol{
                                        padding: 0 16px;
                                        margin: 0;

                                        li {
                                            color: var(--Text-High-Emphasis, #333);
                                            font-family: "Myriad Pro";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 22px; /* 137.5% */
                                        }
                                    }
                                }

                                .trivias-title-container {

                                    .available {
                                        border-radius: var(--Size-2XL, 24px);
                                        border: 1px solid var(--primario-primario-7-base, #ED1C27);
                                        background: var(--Secundarios-Neutro-9, #FFF);
                                        padding: 4px 8px;
                                        color: var(--primario-primario-7-base, #ED1C27);
                                        text-align: center;
                                        font-family: "Myriad Pro";
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 22px; /* 157.143% */
                                        width: fit-content;
                                        margin: 0 auto;
                                    }
                                }

                                .trivias-body-container {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                            }
                            .note {
                                color: var(--Text-Medium-Emphasis, #707070);
                                text-align: center;
                                /* CAP/c-Semibold */
                                font-family: "Myriad Pro";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px; /* 133.333% */
                                max-width: 200px;
                                margin: 24px auto 0;
                            }

                            .trivias-container {
                                border-radius: 20px;
                                border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                                background: var(--Secundarios-Neutro-9, #FFF);
                                /* Shadow card negra */
                                box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                                padding: 24px 12px;
                                min-width: 282px;

                                .trivias-title-container {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    .info {
                                        color: var(--Text-High-Emphasis, #333);
                                        /* Title/h5-Semibold */
                                        font-family: "Myriad Pro Semibold";
                                        font-size: 24px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 28px; /* 116.667% */
                                        margin: 0;
                                    }

                                    .points {
                                        border-radius: 100px;
                                        background: linear-gradient(90deg, #A9A9A9 -7.65%, #FFF 50%, #A9A9A9 107.65%);
                                        padding: 2px 8px;
                                        color: var(--Secundarios-Neutro-4, #333);
                                        /* CAP/c-Semibold */
                                        font-family: "Myriad Pro Semibold";
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 16px; /* 133.333% */
                                        margin: 0;
                                    }

                                    .available {
                                        border-radius: var(--Size-2XL, 24px);
                                        border: 1px solid var(--primario-primario-7-base, #ED1C27);
                                        background: var(--Secundarios-Neutro-9, #FFF);
                                        padding: 4px 8px;
                                        color: var(--primario-primario-7-base, #ED1C27);
                                        text-align: center;
                                        font-family: "Myriad Pro";
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 22px; /* 157.143% */
                                        width: fit-content;
                                        margin: 0;
                                    }
                                }

                                .trivias-body-container {
                                    padding: 24px 0 0;

                                    .trivias-body-question {
                                        p {
                                            color: var(--Text-High-Emphasis, #333);
                                            text-align: center;
                                            /* P1/p-Semibold */
                                            font-family: "Myriad Pro Semibold";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 22px; /* 137.5% */
                                            max-width: 310px;
                                            margin: 0 0 24px;

                                        }
                                    }

                                    hr {
                                        width: calc(100% - 42px);
                                        margin: 0 auto;
                                        border: none;
                                        height: 1px;
                                        background: var(--Secundarios-Neutro-5, #707070);
                                    }

                                    form {
                                        margin: 24px 0 0;
                                        padding: 0 18px 0 21px;
                                        .opcion {
                                            display: flex;
                                            align-items: center;
                                            color: var(--Text-High-Emphasis, #333);
                                            /* P1/p-Regular */
                                            font-family: "Myriad Pro";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 22px;
                                            margin-bottom: 16px;
                                            cursor: pointer;
                                        
                                            input {
                                                display: none;
                                            }
                                          
                                              // Crear radio button personalizado
                                            input + span {
                                                display: inline-block;
                                                width: 18px;
                                                height: 18px;
                                                border: 2px solid #333333;  // Color del borde más grueso
                                                border-radius: 50%;
                                                position: relative;
                                                margin-right: 10px;
                                                transition: all 0.3s ease;
                                            }
                                          
                                              // Cuando el input está seleccionado, cambiar color y agregar el punto interno
                                            input:checked + span {
                                                background-color: #fff;
                                                border: 2px solid #333333; // Color más oscuro para el borde
                                            }
                                          
                                            input:checked + span::after {
                                                content: "";
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                width: 10px;
                                                height: 10px;
                                                background-color: #333333;
                                                border-radius: 50%;
                                                transform: translate(-50%, -50%);
                                            }
                                        }

                                        .respuesta-correcta {
                                            color: var(--Semantic-Go, #008580);
                                            text-align: center;
                                            /* Title/h6-Semibold */
                                            font-family: "Myriad Pro Semibold";
                                            font-size: 20px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: normal;
                                            margin: 24px;
                                        }

                                        .respuesta-incorrecta {
                                            color: var(--primario-primario-7-base, #ED1C27);
                                            text-align: center;
                                            /* Title/h6-Semibold */
                                            font-family: "Myriad Pro Semibold";
                                            font-size: 20px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: normal;
                                            margin: 24px 0 4px;
                                        }
                                        
                                        .respuesta-incorrecta-message {
                                            color: var(--Text-High-Emphasis, #333);
                                            text-align: center;
                                            /* P1/p-Regular */
                                            font-family: "Myriad Pro";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 22px; /* 137.5% */

                                            strong {
                                                /* P1/p-Semibold */
                                                font-family: "Myriad Pro Semibold";
                                                font-weight: 600;
                                            }
                                        }

                                        .tip {
                                            color: var(--Text-Medium-Emphasis, #707070);
                                            /* CAP/c-Regular */
                                            font-family: "Myriad Pro";
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 16px;
                                            text-align: center;
                                            margin: 4px auto 24px;
                                            width: 255px;

                                            strong {
                                                /* CAP/c-Semibold */
                                                font-family: "Myriad Pro Semibold";
                                                font-size: 12px;
                                                font-weight: 600;
                                            }
                                        }

                                        .button-container {
                                            margin-bottom: 8px;
                                        }
                                    }
                                }
                            }

                        }

                        .status-container {
                            margin: auto;
                            justify-content: center;
                            display: flex;
                            flex-direction: column;

                            @media only screen and (max-width: 992px) {
                                flex-direction: column;
                            }

                            img {
                                margin: auto;
                            }

                            .title {
                                color: var(--Text-High-Emphasis, #333);
                                text-align: center;
                                /* Title/h4-Semibold */
                                font-family: "Myriad Pro";
                                font-size: 28px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 32px; /* 114.286% */ 
                                margin: 12px 0;
                            }

                            .desc {
                                color: var(--Text-High-Emphasis, #333);
                                text-align: center;
                                /* P1/p-Semibold */
                                font-family: "Myriad Pro Semibold";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px; /* 137.5% */
                                max-width: 522px;
                                margin: 0;

                                &.type-2 {
                                    max-width: 100%;
                                    margin: 0 0 20px;
                                }

                                &.type-3 {
                                    color: var(--Text-High-Emphasis, #333);
                                    text-align: center;
                                    /* Title/h6-Regular */
                                    font-family: "Myriad Pro";
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    margin: 20px 0 0;
                                    max-width: 400px;

                                    strong {
                                        font-family: "Myriad Pro Black";
                                        font-weight: 900;
                                    }

                                    @media only screen and (max-width: 992px) {
                                        max-width: 315px;
                                    }
                                }
                            }

                            .button-container {
                                margin-top: 16px;
                            }

                            .link-button-container {
                                margin-top: 30px;
                            }

                            .puntajes {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                gap: 20px;

                                .puntajes-item {
                                    border-radius: 8px;
                                    background: #FFF;
                                    width: 420px;
                                    text-align: center;
                                    padding: 7px 14px;

                                    @media only screen and (max-width: 992px) {
                                        width: 270px;
                                    }

                                    span {
                                        color: var(--Text-High-Emphasis, #333);
                                        font-family: "Myriad Pro Black";
                                        font-size: 14.122px;
                                        font-style: normal;
                                        font-weight: 900;
                                        line-height: 19.418px; /* 137.5% */
                                    }
                                }

                                .info-pos {
                                    border-radius: 16px 16px 0px 0px;
                                    background: var(--Secundarios-Neutro-4, #333);
                                    padding: 4px 8px;
                                    max-width: 238px;
                                    margin: auto;
                                    text-align: center;

                                    span {
                                        color: var(--Text-Inverted-Hg-Emphasis, #FFF);
                                        text-align: center;
                                        font-family: "Myriad Pro";
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 16px; /* 114.286% */
                                    }
                                }
                            }
                        }
                    }
    
                    .puntaje-desc {
                        padding: 20px 12px 24px;
    
                        .profile-container {
                            display: flex;
                            gap: 16px;
                            align-items: center;
                            padding-right: 50px;
    
                            img {
                                width: 110px;
                                height: 110px;
                            }
    
                            .puntaje-points-container {
                                p {
                                    margin: 0;
                                }
                                .points {
                                    color: var(--primario-primario-7-base, #ED1C27);
                                    font-family: "Myriad Pro Bold";
                                    font-size: 64px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                }
    
                                .points-text {
                                    color: var(--Text-High-Emphasis, #333);
                                    font-family: "Myriad Pro";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 137.5% */
                                    margin-top: -6px;
                                    margin-left: 4px;
                                }
                            }
                        }
    
                        .actividades-container {
                            margin: 20px 0;
                            .toggle {
                                position: relative;
                                border-bottom: 1px solid var(--Secundarios-Neutro-4, #333);
                                .toggle-icon{
                                    position: absolute;
                                    width: 28px;
                                    height: 28px;
                                    right: 21px;
                                    top: 17px;
                                    transition: all 0.2s cubic-bezier(0.77, 0, 0.175, 1);
    
                                    svg {
                                        border-radius: 100px;
                                        background: var(--Secundarios-Neutro-4, #333);
                                        padding: 4px;
                                        transition: all .3s cubic-bezier(.77,0,.175,1);
                                    }
    
                                    &.active {
                                        svg {
                                            background: var(--primario-primario-7-base, #ED1C27);
                                        }
                                    }
                                }
    
                                .toggle-text {
                                    color: var(--Text-High-Emphasis, #333);
                                    font-family: "Myriad Pro";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 137.5% */
                                    padding: 8px 16px;
                                    max-width: 250px;
                                }
                            }
                        }
    
                        .actualizacion-container {
                            max-width: 180px;
                            text-align: center;
                            margin: auto;
    
                            span {
                                color: var(--Text-Medium-Emphasis, #707070);
                                text-align: center;
                                /* CAP/c-Semibold */
                                font-family: "Myriad Pro";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px; /* 133.333% */
                            }
                        }
                    }
                }
            }
        }

        .mc-custom-modal__center--window img {
            width: auto;
        }

        .mc-custom-modal__center--window .mc-custom-modal__center--window__title {
            &.status {
                margin: 16px 0 0;
                color: var(--Text-High-Emphasis, #333);
                /* Title/h5-Regular */
                font-family: "Myriad Pro";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;

                strong {
                    color: var(--Text-High-Emphasis, #333);
                    text-align: center;
                    /* Title/h5-Semibold */
                    font-family: "Myriad Pro Semibold";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; /* 116.667% */
                }
            }
        }

        .mc-custom-modal__center--window .mc-custom-modal__center--window__desc {
            &.status {
                color: var(--Text-High-Emphasis, #333);
                /* Title/h6-Regular */
                font-family: "Myriad Pro";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

.wallets-container {
    border-radius: 20px;
    border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
    background: var(--Secundarios-Neutro-9, #FFF);
    box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
    padding: 24px 16px;
    height: fit-content;
    margin-top: 15px;
    min-width: 282px;

    @media only screen and (max-width: 992px) {
       
    }

    &.type-2 {
        background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
        box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
        padding: 16px;
        margin-top: 0;
    }

    .title {
        color: var(--Text-High-Emphasis, #333);
        text-align: center;
        font-family: "Myriad Pro BoldSemiCn";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
        max-width: 291px;
        margin: 0 auto;
    }

    .tabs-container {
        padding: 0;
        display: flex;
        justify-content: center;

        .section-tab-login {
            border-radius: 118.357px;
            border: 1.184px solid var(--Secundarios-Neutro-6, #939393);
            background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
            width: 100%;
            padding: 4px;
            max-width: 262px;
            margin: 20px auto;

            .tab {
                display: flex;
                align-items: center;
    
                .tab-item {
                    cursor: pointer;
                    padding: 7.101px 20px;
                    min-width: 84px;
                    text-align: center;
        
                    &.active {
                        border-radius: 71.014px;
                        border: 2px solid var(--Primario-Primario-8, #C70E18);
                        box-shadow: 0px 85px 24px 0px rgba(235, 15, 15, 0.00), 0px 54px 22px 0px rgba(235, 15, 15, 0.01), 0px 31px 18px 0px rgba(235, 15, 15, 0.05), 0px 14px 14px 0px rgba(235, 15, 15, 0.09), 0px 3px 7px 0px rgba(235, 15, 15, 0.10);
                    }
        
                    h3 {
                        align-items: center;
                        display: flex;
                        color: var(--Text-High-Emphasis, #333);
                        text-align: center;
                        font-family: "Myriad Pro";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px;
                        gap: 5px;
                        justify-content: center;
                        margin: 0;
                    }
                }
            }
        }
    }

    .instrucciones {
        max-width: 291px;

        ol{
            padding: 0 16px;
            margin: 0;

            li {
                color: var(--Text-High-Emphasis, #333);
                font-family: "Myriad Pro";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 137.5% */
                text-align: left;
            }
        }
    }
}

.extra-space {
    display: flex;
    flex-direction: column;
    gap:24px 
}

.note {
    color: var(--Text-Medium-Emphasis, #707070);
    text-align: center;
    /* CAP/c-Semibold */
    font-family: "Myriad Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    max-width: 200px;
    margin: 24px auto 0;
}

