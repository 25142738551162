.login-progress-container {
    width: 100%;
    height: 4px;
    background-color: gray; /* Color de fondo gris */
    border-radius: 5px;
    overflow: hidden;
    max-width: 350px;
    margin: 0 auto;

    @media only screen and (max-width: 992px) {
      max-width: 300px;
    }
  }
  
  .login-progress-bar {
    height: 100%;
    width: 0%;
    background-color: red; /* Color de la barra roja */
    transition: width 0.03s linear;
  }