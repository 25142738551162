.mc-header-container {
  z-index: 3;
  position: absolute;
  left: 0px;
  width: 100%;
  top: 0px;

  &__menu {
    position: relative;
    height: 60px;
    background-color: #fff;

    @media only screen and (min-width: 992px) {
      z-index: 2;
    }
  }

  &__submenu {
    position: relative;
    height: 60px;
    background-color: rgb(141, 141, 141);

    @media only screen and (min-width: 992px) {
      z-index: 1;
    }

    &.not-login {
      display: none;
    }
  }

  &__logo {
    z-index: 1;
    text-align: center;
    height: 100%;
    max-width: 260px;
    display: block;
    margin: auto;

    &.logged {
      margin: 0;
    }

    img {
      height: auto;
      left: 0;

      &.log {
        width: 69%;
        position: absolute;
      }
    }

    @media only screen and (min-width: 992px) {
      display: none;
    }
  }

  &__logo--desktop {
    position: absolute;
    left: 75px;
    top: 15px;
    display: none !important;
    width: 260px;
    z-index: 1;
    text-align: center;
    height: auto;
    max-width: 260px;

    img {
      width: 100%;
      height: auto;
    }

    @media only screen and (min-width: 992px) {
      display: block !important;
    }
  }

  &__mobile--close {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: right;
    height: 100%;
    max-width: 80px;

    .icon-close_session {
      width: 26px;
      height: 26px;
      position: relative;
      display: inline-block;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (min-width: 992px) {
      background-color: rgb(255, 255, 255);
      text-align: center;
      height: 42px;
      bottom: 0px;
      margin: auto;
      width: 70px;
    }
  }

  &__mobile--submenu {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: right;
    height: 100%;
    max-width: 80px;
    cursor: pointer;

    .icon-menu {
      position: relative;
      top: 2px;
      transform: scale(0.9);
    }
  }

  &__nav {
    position: fixed;
    right: 0px;
    left: 0px;
    text-align: right;
    bottom: 0px;
    z-index: 10;
    background: var(--Secundarios-Neutro-9, #FFF);
    height: 60px;
    

    @media only screen and (min-width: 992px) {
      position: absolute;
      top: 0px;
      width: auto;
      background-color: transparent;
      max-width: fit-content;
      margin: auto;
      left: 19%;
    }

    ul {
      padding: 0px;
      margin: 0px;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 2;
      
      li {
        width: 20%;
        height: 100%;
        list-style: none;
        text-align: center;
        height: 40px;
        top: 10px;
        margin: auto;
        display: inline-block;

        @media only screen and (min-width: 992px) {
          width: auto;
          float: none;
          display: inline-block;
          vertical-align: middle;
          padding: 0 12px;
        }

        a,
        .mc-header-container__nav--button {
          display: block;
          height: 100%;
          width: 100%;
          cursor: pointer;
          text-decoration: none;
          

          span {
            color: var(--Text-High-Emphasis, #333);
            text-align: center;
            font-family: "Myriad Pro";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }

          @media only screen and (max-width: 992px) {
            font-size: 12px;
          }

          .mc-icon {
            @media only screen and (min-width: 992px) {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
            }
          }
        }

        &.mc-header-container__nav--item {
          background-color: #fff;
          
          .mc-app-centry {
            border-bottom: 4px solid transparent;
            box-sizing: border-box;
            &:hover {
              border-bottom: 2px solid #000;
            }
          }

          &-active {
            .mc-app-centry {
              border-bottom: 4px solid var(--primario-primario-7-base, #ED1C27);

              &:hover {
                border-bottom: 4px solid var(--primario-primario-7-base, #ED1C27) !important;
              }
            }
            
            @media only screen and (max-width: 992px) {
              .mc-app-centry {  
                &:hover {
                  border-bottom: none;
                }
              }
            }

          }

          &.hide {
            display: none;
          }
        }
      }
    }

    &--extra {
      position: fixed;
      right: -20px;
      bottom: 70px;
      z-index: 1;
      box-sizing: border-box;
      padding: 12px 20px;
      text-align: left;
      min-width: 170px;
      outline: 1px solid rgb(230, 230, 230);
      visibility: hidden;
      opacity: 0;
      border-radius: 12px;
      border: 1px solid var(--Secundarios-Neutro-7, #CCC);
      background: var(--Secundarios-Neutro-9, #FFF);

      @media only screen and (min-width: 992px) {
        position: absolute;
        top: 60px;
        bottom: auto;
        right: -10px !important;
        outline: transparent;
        -webkit-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.1);
        box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.1);
      }

      .mc-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        @media only screen and (min-width: 992px) {
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
        }
      }

      a,
      .mc-header-container__nav--close {
        display: block;
        padding: 10px 0px;
        text-decoration: none;
        cursor: pointer;
        color: var(--Text-High-Emphasis, #333);
        /* CAP/c-Regular */
        font-family: "Myriad Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */

        &:hover {
          border-bottom: 2px solid #000;
        }

        &.hide {
          display: none;
        }
      }

      &.opened {
        visibility: visible;
        opacity: 1;
        right: 0px;
      }

    }
  }

  &__summary {
    position: absolute;
    right: 0px;
    top: 24px;
    height: 38px;
    width: 158px;
    -webkit-border-top-left-radius: 200px;
    -webkit-border-bottom-left-radius: 200px;
    -moz-border-radius-topleft: 200px;
    -moz-border-radius-bottomleft: 200px;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 4px 8px;
    box-shadow: 0px var(--XS, 4px) 34px 0px rgba(77, 77, 77, 0.30);

    .mc-preloader-container {
      transform: scale(0.8);
      top: -3px;
    }

    @media only screen and (min-width: 992px) {
      top: 20px;
      height: 38px;
    }

    .mc-app-centry {
      position: relative;
      top: -2px;
    }

    &--item {
      display: inline-block;
      vertical-align: middle;
      width: -webkit-fill-available;
      height: 24px;
      border-radius: 40px;
      background-color: gray;
      text-align: center;
      margin: 0 4px;
      color: var(--Secundarios-Neutro-9, #FFF);
      text-align: center;
      font-family: 'Myriad Pro Bold';
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 23px;

      .mc-icon {
        display: inline-block;
        vertical-align: middle;
        transform: scale(0.9);
        position: relative;
        top: 1px;
      }

      &.amount {
        border-radius: var(--Size-2XL, 24px);
        background: var(--Primario-Primario-oscuro, #C90C14);
        box-shadow: 0px 0px 4px 0px #ED1C27;
        top: 2px;

        svg {
          path {
            fill: #fff;
          }
        }
      }
      &.trx {
        background: var(--Terciarios-Naranja-1, #F8991D);
        box-shadow: 0px 0px var(--M, 16px) 0px #F99F1C;
        color: #131112;
        top: 2px;
      }
    }

    a {
      color: var(--Primario-Primario-oscuro, #C90C14);
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration: underline;
      margin-left: 8px;
      position: relative;
      top: 2px;
    }

    .tooTip-progress {
      position: absolute;
      top: -15px;
      right: -10px;
      box-sizing: border-box;
      padding: 1px 7px;
      border-radius: 8px 8px 0px 0px;
      background: #fff;

      color: var(--Contenidos, #131112);
      font-family: "Myriad Pro";
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 160% */
    }
  }

  &.mc-header-container-page-login {
    .mc-header-container__menu {
      background-color: #fff !important;
      height: 60px;
      display: flex;
      flex-direction: column-reverse;

      .mc-header-container__logo--desktop {
        position: absolute;
        left: 75px;
        top: 26.93px;
        display: none !important;
        width: 260px;
        z-index: 1;
        text-align: center;
        height: auto;
        max-width: 260px;

        img {
          width: 100%;
          height: auto;
        }

        @media only screen and (min-width: 992px) {
          display: block !important;
          position: relative;
          left: 0;
          margin: auto;
        }
      }

      .mc-header-container__title--container {
        max-width: 332px;
        margin: 24px auto;
        text-align: center;

        .mc-header-container__title {
          color: var(--Text-High-Emphasis, #333);
          font-family: "Myriad Pro Bold";
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: 32px;
        }

        .mc-header-container__title--red {
          color: var(--primario-primario-7-base, #ED1C27);
          font-family: "Myriad Pro Bold";
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: 32px; /* 100% */
        }
      }

      @media only screen and (min-width: 992px) {
        height: 80px;
        display: block;

        &::before {
          display: none;
        }

        .mc-header-container__logo {
          background-color: transparent !important;
        }

        .mc-header-container__title--container {
          position: absolute;
          right: 73px;
          top: 26.93px;
          max-width: fit-content;
          margin: auto;

          .mc-header-container__title {
            color: var(--Text-High-Emphasis, #333);
            font-family: "Myriad Pro Bold";
            font-size: 32px;
            font-style: normal;
            font-weight: 900;
            line-height: 32px;
          }

          .mc-header-container__title--red {
            color: var(--primario-primario-7-base, #ED1C27);
            font-family: "Myriad Pro Black";
            font-size: 32px;
            font-style: normal;
            font-weight: 900;
            line-height: 32px; /* 100% */
          }
        }
      }

      .mc-app-fullheight {
        height: 100%;
        padding: 24px 0 24px;

        @media only screen and (max-width: 992px) {
          padding: 0;
        }
      }
    }
    .mc-header-container__mobile--submenu,
    .mc-header-container__nav {
      display: none !important;
    }
    .mc-header-container__submenu {
      display: none !important;
    }
  }

  &.mc-header-container-type-2 {

    .mc-header-container__menu {
      text-align: center;

      @media only screen and (min-width: 992px) {
        height: 110px;
      }

      .mc-header-container__logo {
        left: 0px;
        right: 0px;
        margin: auto;

        @media only screen and (min-width: 992px) {
          height: 50%;
          width: 100%;
          max-width: 230px;
        }
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          left: 0px;
          right: 0px;
          text-align: center;
          top: auto;
          bottom: 0px;
          height: 50%;

          .mc-header-link {
            line-height: 52px;
          }

          .mc-header-container__nav--extra {
            right: -32% !important;
            left: 0;
            width: 300px;
            margin: auto;
            top: 55px;
          }
        }
        ul {
          li {
            .mc-icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;
            }

            .mc-app-centry {
              & > span {
                display: inline-block;
                padding: 5px 7px;
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                & > span {
                  background-color: #000;
                  color: #fff;

                  .mc-icon {
                    svg {
                      path {
                        fill: #fff !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-3 {

    .mc-header-container__menu {
      text-align: left;
      @media only screen and (min-width: 992px) {
        background-color: transparent !important;
      }

      .mc-header-container__logo {
        left: 20px;
        right: auto;
        margin: auto;
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          background-color: rgb(141, 141, 141);
          right: 0px;
          left: auto;
          width: auto;
          -webkit-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.3);
          -moz-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.3);

          .mc-header-container__nav--extra {
            right: 0px !important;
          }
        }

        ul {
          li {

            a,
            .mc-header-container__nav--button {
              &:hover {
                opacity: 1;
              }
            }

            .mc-header-container__nav--button {
              .mc-app-centry {
                & > span {
                  .mc-icon {
                    display: none !important;

                    @media only screen and (min-width: 992px) {
                      display: inline-block !important;
                      position: relative;
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
            
            .mc-icon {
              position: absolute;
              left: 0px;
              right: 0px;
              top: -26px;
              background-color: #fff;
              border-radius: 100px;
              width: 40px;
              height: 40px;
              text-align: center;
              margin: auto;
              -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
              -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
              box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
              opacity: 0;

              @media only screen and (min-width: 992px) {
                position: relative;
                top: auto;
                background-color: transparent;
                -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0);
                -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0);
                box-shadow: 0px 3px 8px 0px rgba(0,0,0,0);
                opacity: 1;
                width: 35px;
                height: 35px;
                top: 4px;
              }

              & > span {
                position: relative;
                top: 8px;

                @media only screen and (min-width: 992px) {
                  top: auto;
                }
              }
            }

            .mc-app-centry {
              & > span {
                display: inline-block;
                padding: 5px 7px;

                @media only screen and (min-width: 992px) {
                  top: -4px;
                  position: relative;
                }
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                & > span {
                  color: #fff;

                  .mc-icon {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-4 {

    @media only screen and (min-width: 992px) {
      z-index: 9;
    }

    @media only screen and (min-width: 992px) {
      .mc-header-container__menu {
        .mc-header-container__logo {
          left: 0px;
          right: 0px;
          margin: auto;
        }
        .mc-header-container__mobile--submenu {
          .mc-icon {
            svg {
              transform: scale(0.85);
            }
          }
        }

        .mc-header-container__nav {
          ul {
            @media only screen and (min-width: 992px) {
              padding-right: 10px;
            }
          }
        }
      }
    }

    .mc-header-container__nav {
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 999;
      height: auto;
      width: auto;
      background-color: transparent;
      visibility: hidden;
      -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
      -o-transition: all .3s cubic-bezier(.77,0,.175,1);
      transition: all .3s cubic-bezier(.77,0,.175,1);

      .mc-header-container__mobile--overlay {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        z-index: 1;
        background-color: rgba(0,0,0,0.6);
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);
        visibility: hidden;
        opacity: 0;
      }

      .mc-header-container__nav--close {
        position: absolute;
        right: -15px;
        top: 12px;
        color: $colorText;
        display: block;
        text-align: right;
        z-index: 3;
        padding: 16px 26px;
        box-sizing: border-box;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);
      }

      ul {
        background-color: #fff;
        right: -280px;
        top: 0px;
        width: 280px;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        padding-top: 60px;
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);
        visibility: hidden;
        opacity: 0;

        li {
          float: none;
          display: block;
          width: 100%;
          box-sizing: border-box;
          height: auto;
          text-align: right;
          padding: 0 30px;

          a {
            height: 60px;

            .mc-app-centry {
              & > span {
                color: $colorText;
                text-align: left;

                .mc-icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;

                  svg {
                    path {
                      stroke: $colorText;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .mc-header-container__nav--extra {
        display: none !important;
      }
    }

    &.show-menu {
      .mc-header-container__nav {
        visibility: visible !important;

        .mc-header-container__mobile--overlay,
        .mc-header-container__nav--close {
          visibility: visible !important;
          opacity: 1 !important;
          right: 0px !important;
        }

        ul {
          right: 0px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.mc-header-link {
  line-height: 65px;
  font-size: 15px;
  font-weight: 600;
}