.mc-header-container {
    &.mc-header-container-page-preguntas-frecuentes {
        .mc-header-container__menu {
            .mc-header-container__title--container {
                max-width: 332px;
                margin: 24px auto;
                text-align: center;
        
                .mc-header-container__title {
                color: var(--Text-High-Emphasis, #333);
                font-family: "Myriad Pro Bold";
                font-size: 32px;
                font-style: normal;
                font-weight: 900;
                line-height: 32px;
                }
        
                .mc-header-container__title--red {
                color: var(--primario-primario-7-base, #ED1C27);
                font-family: "Myriad Pro Bold";
                font-size: 32px;
                font-style: normal;
                font-weight: 900;
                line-height: 32px; /* 100% */
                }

                @media only screen and (min-width: 992px) {
                    position: absolute;
                    right: 73px;
                    top: 26.93px;
                    max-width: fit-content;
                    margin: auto;
            
                    .mc-header-container__title {
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro Bold";
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 32px;
                    }
            
                    .mc-header-container__title--red {
                        color: var(--primario-primario-7-base, #ED1C27);
                        font-family: "Myriad Pro Bold";
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 32px; /* 100% */
                    }
                    
                }
            }
        }
    }
}

.mc-page {
    &.faqs {
        position: relative;
        padding: 60px 0 70px;
        background: #fff;

        @media only screen and (min-width: 992px) {
            padding: 60px 0 70px;
        }

        .mc-page__title--container {
            border-bottom: 1px solid var(--primario-primario-7-base, #ED1C27);
        }

        &.log {
            padding: 60px 0 70px;
        }
    }
}