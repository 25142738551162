.mc-page{
    &.awards {
        .mc-custom-modal {
            &__center {
                &--window {
                    padding: 1px;
                    border-radius: 12px;
                    width: 330px;
                    margin: auto;
                    overflow-y: scroll;
                    max-height: 600px;
        
                    &::-webkit-scrollbar {
                        display: none;
                    }
        
                    &__image {
                        height: 320px;
                        width: 100%;
                        display: block;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        border-radius: 12px 12px 0 0;
                        margin: 0;
        
                        .img-exp {
                            position: absolute;
                            top: 15px;
                            left: 12px;
                            width: 56%;
                        }
                    }
        
                    &__desc {
                        padding: 16px;
                        margin: 0;
                        h6 {
                            margin: 0;
                            color: var(--primario-primario-7-base, #ED1C27);
                            font-family: "Myriad Pro";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-align: start;
                        }
        
                        ul {
                            padding-left: 24px;
        
                            li {
                                color: var(--Secundarios-Neutro-4, #4D4D4D);
                                font-family: "Myriad Pro";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px; /* 137.5% */
                                text-align: start;
                            }
                        }
                    }
                }
            }
        
            &__close {
                z-index: 999;
                top: 10px;
                right: 12px;
                
                svg {
                    background: #fff;
                    border-radius: 30px;
                    padding: 2px;
                }
        
                .close-img {
                    width: 24px;
                }
            }
        }
    }
} 