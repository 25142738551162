.mc-custom-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    background-color: #000;
    opacity: 70%;
  }

  &__center {
    width: 430px;
    height: 100%;
    margin: 0 auto;
    max-width: 90%;
    z-index: 1;

    &--window {
      position: relative;
      background: #F4F2FA;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      border-radius: 6px;
      box-sizing: border-box;
      padding: 16px;
      box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);
      -webkit-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);

      &__image {
        height: 80px;
        width: 90px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        box-sizing: border-box;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: 80% !important;
      }
      &__title {
        text-align: center;
        margin: 24px 0px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        color: $colorText;
      }
      &__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin: 18px 0;
      }
      &__actions {
        margin: 20px 0px;

        @media only screen and (min-width: 992px) {
          margin-top: 30px;
        }

        .mc-button-container {
          margin: 10px 0px;
        }
      }
      &__terms {
        text-align: center;
        margin-bottom: 16px;
        .custom-a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          color: $colorText;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 22px;
    top: 20px;
    cursor: pointer;
    
    &:hover {
      opacity: 0.6;
    }
  }

  &__opened {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .mc-custom-modal__center--window {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
}