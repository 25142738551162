.mc-page {
    &.redemptions {
        padding: 60px 0 56px;
    }
}
.mc-custom-modal {
    &__center {
        &--window {
            border-radius: 20px;
            border: 2px solid var(--Secundarios-Neutro-7, #CCC);
            background: var(--Neutro-Neutro-9, #FFF);
            /* Red shadow */
            box-shadow: 0px 85px 24px 0px rgba(235, 15, 15, 0.00), 0px 54px 22px 0px rgba(235, 15, 15, 0.01), 0px 31px 18px 0px rgba(235, 15, 15, 0.05), 0px 14px 14px 0px rgba(235, 15, 15, 0.09), 0px 3px 7px 0px rgba(235, 15, 15, 0.10);           
            box-sizing: border-box;
            opacity: 0;
            padding: 16px;
            position: relative;
            transform: scale(0);
            visibility: hidden;
            width: 100%;
            max-height: none;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            img {
                display: block;
                height: auto;
                margin: 0 auto;
                width: 120px;
                border-radius: 0;
            }

            .mc-custom-modal__center--window__head {
                color: var(--Color-Black-1, #0F0F0F);
                text-align: center;
                font-family: "Myriad Pro Semibold";
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px; /* 110% */
                margin-bottom: 0;
                margin-top: 14px;
            }

            .mc-custom-modal__center--window__title {
                color: var(--Color-Black-1, #0F0F0F);
                text-align: center;
                font-family: "Myriad Pro";
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px; /* 114.286% */
                margin-top: 5px;

                strong {
                    color: var(--primario-primario-7-base, #ED1C27);
                    font-family: "Myriad Pro Black";
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 32px;
                }
            }

            .mc-custom-modal__center--window__actions.redemtion {
                margin-bottom: 10px;
                margin-top: 10px;

                .mc-button-container {
                    display: inline-block;
                    vertical-align: middle;
                    width: 48%;

                    &:first-child {
                        margin-right: 2%;
                    }

                    &:nth-child(2) {
                        margin-left: 2%;
                    }

                    @media only screen and (max-width: 992px) {
                        display: block;
                        width: 100%;

                        &:first-child {
                            margin-right: 0;
                        }
    
                        &:nth-child(2) {
                            margin-left: 0;
                        }
                    }
                }
            }

            &__desc {
                padding: 16px;
                margin: 0;
                h6 {
                    margin: 0;
                    color: var(--primario-primario-7-base, #ED1C27);
                    font-family: "Myriad Pro";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: start;
                }

                ul {
                    padding-left: 24px;

                    li {
                        color: var(--Secundarios-Neutro-4, #4D4D4D);
                        font-family: "Myriad Pro";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 137.5% */
                        text-align: start;
                    }
                }
            }
        }
    }

    &__close {
        z-index: 999;
        svg {
            background: #fff;
            border-radius: 30px;
            padding: 2px;
        }
    }
}