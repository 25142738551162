.mc-alliances-container {
  margin-top: 56px;
  border-radius: 20px 20px 20px 20px;
  background: var(--Secundarios-Neutro-8, #F0F0F0);
  /* Shadow card red */
  box-shadow: 0px 258px 72px 0px rgba(0, 0, 0, 0.00), 0px 165px 66px 0px rgba(0, 0, 0, 0.01), 0px 93px 56px 0px rgba(0, 0, 0, 0.05), 0px 41px 41px 0px rgba(0, 0, 0, 0.09), 0px 10px 23px 0px rgba(0, 0, 0, 0.10);
  padding-bottom: 32px;

  &__items {
    padding: 32px 25px 0;

    @media only screen and (max-width: 992px) {
      padding: 32px 16px;
    }

    &--item {
      position: relative;
      margin-bottom: 30px;

      &__container {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        min-height: 160px;
      }

      &__image {
        display: inline-block;
        vertical-align: top;
        width: 25%;

        img {
          width: 100%;
          height: auto;
          display: block;

          &.dummy {
            opacity: 0 !important;
          }
        }
      }

      &__info {
        display: inline-block;
        vertical-align: top;
        width: 75%;
        box-sizing: border-box;
        padding-left: 10px;

        .mc-alliances-button {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
          color: #131112;
          margin: 8px 0px;
          display: block;
          border: 0px;
          background-color: transparent;
          padding: 0px;
          cursor: pointer;
        }
      }

      &__title {
        color: var(--Text-High-Emphasis, #333);
        font-family: "Myriad Pro";
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: var(--Fonts-Line-height-20, 20px); /* 83.333% */
        margin-bottom: 8px;
      }

      &__rule {
        color: var(--primario-primario-7-base, #ED1C27);
        font-family: "Myriad Pro";
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: var(--Fonts-Line-height-24, 24px); /* 100% */
        margin: 8px 0;
      }

      &__desc {
        
        * {
          color: var(--Text-High-Emphasis, #333);
          font-family: "Myriad Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        strong{
          color: var(--Text-High-Emphasis, #333);
          font-family: "Myriad Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      &__container {
        &.terms {
          .mc-custom-modal__center {
            @media only screen and (min-width: 992px) {
              width: 900px;
            }

            .mc-custom-modal__center--window {
              max-height: 80%;

              .mc-custom-modal__center--window__desc {
                text-align: left;
                max-height: 280px;
                overflow: auto;

                @media only screen and (min-width: 992px) {
                  max-height: 320px;
                }
              }
            }
          }
        }
      }

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        color: $colorText;
        margin: 8px 0px;
        display: block;
      }

      &.type-1 {

      }
      &.type-2 {
        margin-bottom: 40px;

        .mc-alliances-container__items--item__image {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 80px;
          height: 80px;
        }
        .mc-alliances-container__items--item__info {
          height: 80px;
          position: relative;
          padding-left: 90px;
          width: 100%;
          margin-bottom: 12px;

          .mc-alliances-container__items--item__rule {
            background-color: $primaryColor;
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            text-align: center;
            padding: 7px 0px;
          }
          .mc-alliances-container__items--item__title {
            position: absolute;
            bottom: 0px;
            left: 90px;
            margin-bottom: 0px;
          }
          
        }
      }
      &.type-3 {
        background-color: rgb(233, 233, 233);
        padding: 18px;
        padding-bottom: 12px;
        box-sizing: border-box;

        .mc-alliances-container__items--item__info {
          height: 75px;
          position: relative;
          width: 100%;
          background-color: rgb(196, 196, 196);
          border-radius: 8px;
          padding: 10px;
          box-sizing: border-box;
          padding-left: 85px;
          margin-bottom: 12px;

          .mc-alliances-container__items--item__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: 22px;
            margin-top: 0px;
            margin-bottom: 2px;
          }

          .mc-alliances-container__items--item__rule {
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 28px;
          }

          .mc-alliances-container__items--item__image {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 75px;
            height: 75px;
            -webkit-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.3);
            -moz-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.3);
            box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.3);
          }
        }
      }
      &.type-4 {
        .mc-alliances-container__items--item__info {
          padding-left: 50px;
          width: 100%;

          .mc-alliances-container__items--item__labels {
            background-color: rgb(243, 243, 243);
            box-sizing: border-box;
            padding: 16px;
            padding-left: 50px;

            .mc-alliances-container__items--item__title {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
            }

            .mc-alliances-container__items--item__rule {
              font-size: 24px;
              font-style: normal;
              font-weight: 900;
              line-height: 28px;
              margin-bottom: 6px;
            }

            .mc-alliances-container__items--item__desc {
              * {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }

          .mc-alliances-container__items--item__image {
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            width: 86px;
            z-index: 1;
            height: 86px;

            span {
              border-radius: 200px;
              background-color: #fff;
              background-size: 60% !important;
              -webkit-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.25);
              -moz-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.25);
              box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.25);
            }
          }
        }
      }
    }

    .SectionAssistanceServices{
      max-width: 850px;
      margin: auto;

      p {
        color: var(--Text-High-Emphasis, #333);
        text-align: center;
        font-family: "Myriad Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        margin: 0 0 24px;
      }

      .btn-allies-assistance {
        max-width: 300px;
        margin: auto;
      }
    }
  }

  &__title {
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid var(--Primario-Primario-2, #FFDFE1);
    background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
    padding: 12px 16px;

    h4 {
      align-items: center;
      display: flex;
      color: var(--Text-High-Emphasis, #333);
      font-family: "Myriad Pro";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      gap: 10px;
      margin: 0;
    }

    svg {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .mc-alliances-container__actions {
    background-color: $primaryColor;
    text-align: center;
    margin: 18px auto;
    max-width: 390px;

    a {
      color: #fff;
      text-decoration: none;
      display: block;
      padding: 10px 0;
      
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  &.mc-alliances-column-1 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }

  &.mc-alliances-column-2 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 47%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }

  &.mc-alliances-column-3 {
    .mc-alliances-container__items {

      // @media only screen and (min-width: 992px) {
      //   margin-left: -1.5%;
      //   margin-right: -1.5%;
      // }

      &--item {
        border-radius: 12px;
        border-bottom: 4px solid var(--Primario-Primario-9, #A41018);
        background: #fff;
        margin-bottom: 20px;

        @media only screen and (min-width: 992px) {
          border-radius: 12px;
          border-bottom: 4px solid var(--Primario-Primario-9, #A41018);
          width: 30.3%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 24px;
          background: #fff;
        }
      }
    }
  }
}

.assistance-container {
  border-radius: 20px;
  background: var(--Secundarios-Neutro-8, #F0F0F0);
  padding: 32px 25px;
  margin-top: 32px;

  h2 {
    color: var(--Text-High-Emphasis, #333);
    text-align: center;
    /* Title/h4-Semibold */
    font-family: "Myriad Pro";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */
    margin: 0;
  }

  .mc-assistants {
    display: block;
    margin-top: 24px;
    width: 100%;

    .mc-assistants--item {
      background: var(--Secundarios-Neutro-9, #FFF);
      border: 1.174px solid #bbb;
      border-radius: 18px 18px 18.778px 18.778px;
      box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
      display: inline-block;
      margin: 0 10px 30px;
      vertical-align: top;
      width: 31%;

      @media only screen and (max-width: 992px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-bottom: 24px;
      }

      .mc-assistants--item__header {
        border-radius: 18px 18px 0px 0px;
        border-bottom: 1px solid var(--Primario-Primario-6, #FE3540);
        background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
        padding: 14px 20px 12px 20px;

        .mc-assistants--item__header--title {
          align-items: center;
          display: flex;
          color: var(--Text-High-Emphasis, #333);
          font-family: "Myriad Pro Semibold";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          gap: 11px;
          margin: 0;
        }
      }

      .mc-assistants--item__body {
        padding: 12px 25px;
        color: var(--Text-High-Emphasis, #333);
        font-family: "Myriad Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.126px; /* 132.035% */
        height: 260px; 
         
        @media only screen and (max-width: 992px) {
          height: auto;
        }

        ol {
          margin: 0;
          padding-left: 30px;
        }
      }

      hr {
        width: calc(100% - 24px);
        margin: 0 auto;
        border: none;
        height: 1px;
        background: var(--Secundarios-Neutro-5, #707070);;
      }

      .mc-assistants--item__footer {
        padding: 12px 10px;

        h3 {
          color: var(--Text-High-Emphasis, #333);
          font-family: "Myriad Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21.126px; /* 150.897% */
          text-align: center;
          margin: 0 0 8px;
        }

        .mc-assistants--item__footer--include {
          box-sizing: border-box;
          text-align: center;
        }
      }
    }
  }

  .SectionAssistanceServices {
    p {
      text-align: center;
      color: var(--Text-High-Emphasis, #333);
      font-family: "Myriad Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */
    }

    .btn-allies-assistance {
      max-width: 300px;
      margin: auto;

      @media only screen and (max-width: 992px) {
        max-width: 100%;
      }
    }
  }
}