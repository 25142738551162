.mc-page {
    &.mechanic {
        padding: 60px 0 0;
        .participar-steps-container {
            padding-top: 56px;
            display: flex;
            gap: 16px;
            justify-content: center;

            .participar-steps__items--item {
                border-radius: 18px 18px 18.778px 18.778px;
                border: 1.174px solid var(--Secundarios-Neutro-8, #F0F0F0);
                background: var(--Secundarios-Neutro-9, #FFF);
                /* Shadow card negra */
                box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                max-width: 326px;
                .participar-steps__items--item--title {
                    border-radius: 18px 18px 0px 0px;
                    border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                    background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                    padding: 14px 20px 12px 20px;

                    .svg-type1 {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .svg-type3 {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    .svg-type2 {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    h6 {
                        align-items: center;
                        display: flex;
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        gap: 10px;
                        margin: 0;

                        .mc-icon {
                            svg {
                              background: var(--Primario-Primario-8, #C70E18);
                              border-radius: 90.498px;
                              padding: 9.05px;
                              box-shadow: 0px 28.371px 7.964px 0px rgba(207, 32, 32, 0.00), 0px 18.416px 7.466px 0px rgba(207, 32, 32, 0.01), 0px 10.452px 5.973px 0px rgba(207, 32, 32, 0.05), 0px 4.48px 4.48px 0px rgba(207, 32, 32, 0.09), 0px 0.995px 2.489px 0px rgba(207, 32, 32, 0.10);
                              position: relative;
                            }
                        }
                    }
                }

                .participar-steps__items--item--desc {
                    padding: 16px 20px 16px 20px;

                    p {
                    color: var(--Text-Medium-Emphasis, #707070);
                    font-family: "Myriad Pro";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    margin: 0;

                    strong {
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 22px;
                    }
                    }
                }

                @media only screen and (max-width: 992px) {
                    margin: auto;
                }
            }

            @media only screen and (max-width: 992px) {
                flex-direction: column;
                gap: 24px;
            }
        }

        .participar-rewards {
            padding-top: 32px;
            padding-bottom: 56px;
            max-width: 90%;
            margin: auto;
  
            .participar-rewards-container {
            border-radius: 20px;
            background: var(--Secundarios-Neutro-9, #FFF);
            /* Shadow card negra */
            box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
            
                .participar-rewards-title {
                    border-radius: 20px 20px 0px 0px;
                    border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                    background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                    padding: 16px 20px;
                    text-align: center;
                    
                    svg {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    }

                    h4 {
                        align-items: center;
                        display: flex;
                        color: var(--Secundarios-Neutro-4, #333);
                        font-family: "Myriad Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        gap: 10px;
                        margin: 0;
                        justify-content: center;
                        margin-bottom: 16px;

                        @media only screen and (max-width: 992px) {
                            justify-content: left;
                        }
                    }

                    span {
                    color: var(--Secundarios-Neutro-4, #333);
                    text-align: center;
                    font-family: "Myriad Pro";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    }
                }

                .participar-rewards-desc {
                    padding: 32px 20px 20px;
                    background: var(--Secundarios-Neutro-9, #FFF);
                    border-radius: 0 0 20px 20px;
                    overflow: hidden;
                    
                    .slick-slider {
                        .slick-list {
                            overflow: visible;
                            .slick-track {
                                justify-content: center;
                                display: flex;

                                @media only screen and (max-width: 992px) {
                                    justify-content: left;
                                }
                                
                                .slick-slide {
                                    width: 304px !important;
                                    min-height: 430px;
                                    display: flex;
                                    justify-content: center;

                                    @media only screen and (max-width: 992px) {
                                        width: 272px !important;
                                    }

                                    .participar-rewards__items--item {
                                        border-radius: 20px;
                                        border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                                        background: var(--Secundarios-Neutro-9, #FFF);
                                        box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                                        max-width: 280px;
                                        min-height: 410px;

                                        @media only screen and (max-width: 992px) {
                                            max-width: 260px;
                                        }

                                        img {
                                            border-radius: 20px 20px 0px 0px;
                                            width: 100%;
                                        }

                                        .participar-rewards__items--item--desc {
                                            padding: 16px;

                                            span {
                                            color: var(--Text-High-Emphasis, #333);
                                            font-family: "Myriad Pro";
                                            font-size: 20px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;

                                                strong {
                                                    color: var(--primario-primario-7-base, #ED1C27);
                                                    font-family: "Myriad Pro Black";
                                                    font-size: 20px;
                                                    font-style: normal;
                                                    font-weight: 900;
                                                    line-height: normal;
                                                }
                                            }
                                        }
                                    }   
                                }
                            }
                        }

                        .slick-dots {
                            bottom: -24px;

                            li {
                            background-color: #707070;
                            border-radius: 50px;
                            height: 8px;
                            margin: 0 4px;
                            position: relative;
                            transition: all .3scubic-bezier(.77,0,.175,1);
                            width: 8px;

                            button {
                                width: 100%;
                                height: 100%;

                                &::before {
                                display: none;
                                }
                            }

                            &.slick-active {
                                background-color: #333;
                                width: 20px;
                                height: 8px;
                                border-radius: 20px;
                            }
                            }
                        }

                        .slick-arrow {
                            top: auto;
                            bottom: -28px;
                            transform: translate(0px, 0px);
                            margin: auto;
                            background-position: center center !important;
                            background-repeat: no-repeat !important;
                            background-size: 100% !important;
                            opacity: 1;
                            z-index: 2;
                    
                            &:before {
                            display: none;
                            }
                    
                            &.slick-prev {
                            right: 80px;
                            background-image: url('../../../public/assets/images/arrow-L.png');
                            -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                            transition: all .3s cubic-bezier(.77,0,.175,1);
                            }
                    
                            &.slick-next {
                            left: 80px;
                            background-image: url('../../../public/assets/images/arrow-L.png');
                            transform: rotate(180deg);
                            -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                            -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                            transition: all .3s cubic-bezier(.77,0,.175,1);
                            }
                        }
                    }

                    .participar-button-container {
                        max-width: 300px;
                        margin: 32px auto 0;

                        @media only screen and (max-width: 992px) {
                            margin: 65px auto 0;
                        }
                    }
                }
            }
        }
    }
}