.mc-login__header {
  text-align: center;
  padding: 20px 0px;
  padding-bottom: 0px;

  @media only screen and (min-width: 992px) {
    display: none !important;
  }

  .mc-app-container {
    padding: 0 10px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.mc-page  {
  &.login {
    padding-bottom: 0px;
    
    @media only screen and (min-width: 992px) {
      padding-top: 0px !important;
      background: whitesmoke;
    }

    .mc-login__container {
      position: relative;
      padding: 0;
      background: var(--Red-gradient, linear-gradient(180deg, var(--primario-primario-7-base, #ED1C27) 0%, #FF000F 82%));

      .mc-app-container {
        padding-bottom: 16px;

        .login-form-container {
          justify-content: center;
          display: flex;

          @media only screen and (max-width: 992px) {
            flex-direction: column;
            gap: 24px;
            align-items: center;
          }
        }
      }

      .tabs-container {
        padding: 32px 18px;
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 992px) {
          padding: 32px 6px 24px;
        }

        .section-tab-login {
          border-radius: 118.357px;
          background: var(--Secundarios-Neutro-9, #FFF);
          width: 100%;
          padding: 8px;
          max-width: fit-content;

          .tab {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .tab-item {
              cursor: pointer;
              padding: 7.101px 20px;
              min-width: 110px;

              &.active {
                border-radius: 71.014px;
                border: 2px solid var(--Primario-Primario-8, #C70E18);
                box-shadow: 0px 85px 24px 0px rgba(235, 15, 15, 0.00), 0px 54px 22px 0px rgba(235, 15, 15, 0.01), 0px 31px 18px 0px rgba(235, 15, 15, 0.05), 0px 14px 14px 0px rgba(235, 15, 15, 0.09), 0px 3px 7px 0px rgba(235, 15, 15, 0.10);
              }

              h3 {
                align-items: center;
                display: flex;
                color: var(--Text-High-Emphasis, #333);
                text-align: center;
                font-family: "Myriad Pro";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                gap: 5px;
                justify-content: center;
                margin: 0;
              }
            }
          }
        }
      }

      .participar-container {
        padding: 0 18px;

        .participar-explanation {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 32px;
          padding: 0 0 32px;

          .participar-steps-container {
            padding: 24px 16px;
            align-items: flex-start;
            border-radius: 20px;
            background: var(--Secundarios-Neutro-9, #FFF);
            box-shadow: 0px 518px 145px 0px rgba(0, 0, 0, 0.00), 0px 332px 133px 0px rgba(0, 0, 0, 0.01), 0px 187px 112px 0px rgba(0, 0, 0, 0.05), 0px 83px 83px 0px rgba(0, 0, 0, 0.09), 0px 21px 46px 0px rgba(0, 0, 0, 0.10);
            overflow: hidden;
            max-width: calc(100% - 36px);
            text-align: center;

            h4 {
              color: var(--Secundarios-Neutro-4, #333);
              font-family: "Myriad Pro";
              font-size: 28px;
              font-style: normal;
              font-weight: 600;
              line-height: 32px;
              margin: 0;
              margin-bottom: 12px;
            }

            p {
              color: var(--Secundarios-Neutro-4, #333);
              font-family: "Myriad Pro";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
              margin: 0;
              margin-bottom: 24px;
            }

            .participar-steps__items--item {
              border-radius: 18px 18px 18.778px 18.778px;
              border: 1.174px solid var(--Secundarios-Neutro-8, #F0F0F0);
              background: var(--Secundarios-Neutro-9, #FFF);
              /* Shadow card negra */
              box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
              max-width: 326px;

              .participar-steps__items--item--title {
                border-radius: 18px 18px 0px 0px;
                border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                padding: 14px 20px 12px 20px;

                .svg-type1 {
                  position: absolute;
                  top: 0;
                  left: 0;
                }

                .svg-type3 {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }

                .svg-type2 {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }

                h6 {
                  align-items: center;
                  display: flex;
                  color: var(--Text-High-Emphasis, #333);
                  font-family: "Myriad Pro";
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  gap: 10px;
                  margin: 0;

                  .mc-icon {
                    svg {
                      background: var(--Primario-Primario-8, #C70E18);
                      border-radius: 90.498px;
                      padding: 9.05px;
                      box-shadow: 0px 28.371px 7.964px 0px rgba(207, 32, 32, 0.00), 0px 18.416px 7.466px 0px rgba(207, 32, 32, 0.01), 0px 10.452px 5.973px 0px rgba(207, 32, 32, 0.05), 0px 4.48px 4.48px 0px rgba(207, 32, 32, 0.09), 0px 0.995px 2.489px 0px rgba(207, 32, 32, 0.10);
                    }
                  }
                }
              }

              .participar-steps__items--item--desc {
                padding: 16px 20px 16px 20px;

                p {
                  color: var(--Text-Medium-Emphasis, #707070);
                  font-family: "Myriad Pro";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;

                  strong {
                    color: var(--Text-High-Emphasis, #333);
                    font-family: "Myriad Pro";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px;
                  }
                }
              }
            }

            .slick-slider {
              margin: auto;
              .slick-list {
                overflow: visible;

                .slick-track {
                  display: flex;
                  flex-direction: column;
                  max-width: 340px;
                  gap: 24px;

                  .slick-slide {
                    width: 342px !important;

                    @media only screen and (max-width: 992px) {
                      width: 318px !important;
                      text-align: left;
                    }
                  }
                }
              }

              .slick-dots {
                bottom: -24px;
                left: 44px;
                width: auto;
                text-align: start;

                li {
                  background-color: #707070;
                  border-radius: 50px;
                  height: 8px;
                  margin: 0 4px;
                  position: relative;
                  transition: all .3scubic-bezier(.77,0,.175,1);
                  width: 8px;

                  button {
                    width: 100%;
                    height: 100%;

                    &::before {
                      display: none;
                    }
                  }

                  &.slick-active {
                    background-color: #333;
                    width: 20px;
                    height: 8px;
                    border-radius: 20px;
                  }
                }
              }

              .slick-arrow {
                top: auto;
                bottom: -28px;
                transform: translate(0px, 0px);
                left: 0px;
                right: 0px;
                margin: auto;
                background-position: center center !important;
                background-repeat: no-repeat !important;
                background-size: 100% !important;
                opacity: 1;
                z-index: 2;
          
                &:before {
                  display: none;
                }
          
                &.slick-prev {
                  left: 0;
                  right: auto;
                  background-image: url('../../../public/assets/images/arrow-L.png');
                  -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                  -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                  -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                  transition: all .3s cubic-bezier(.77,0,.175,1);
                }
          
                &.slick-next {
                  right: auto;
                  left: 120px;
                  background-image: url('../../../public/assets/images/arrow-L.png');
                  transform: rotate(180deg);
                  -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                  -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                  -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                  transition: all .3s cubic-bezier(.77,0,.175,1);
                }
              }
            }
          }
        }

        .participar-rewards {

          .mc-app-container {
            text-align: center;
            padding: 0;

            .participar-rewards-container {
              border-radius: 20px;
              background: var(--Secundarios-Neutro-9, #FFF);
              /* Shadow card negra */
              box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
            
              .participar-rewards-title {
                border-radius: 20px 20px 0px 0px;
                border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                padding: 16px 20px;
                
                svg {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }

                h4 {
                  align-items: center;
                  display: flex;
                  color: var(--Secundarios-Neutro-4, #333);
                  font-family: "Myriad Pro";
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  gap: 10px;
                  margin: 0;
                  justify-content: center;
                  margin-bottom: 26.5px;

                  @media only screen and (max-width: 992px) {
                    justify-content: left;
                  }

                  .mc-icon {
                    svg {
                      background: var(--Primario-Primario-8, #C70E18);
                      border-radius: 90.498px;
                      padding: 9.05px;
                      box-shadow: 0px 28.371px 7.964px 0px rgba(207, 32, 32, 0.00), 0px 18.416px 7.466px 0px rgba(207, 32, 32, 0.01), 0px 10.452px 5.973px 0px rgba(207, 32, 32, 0.05), 0px 4.48px 4.48px 0px rgba(207, 32, 32, 0.09), 0px 0.995px 2.489px 0px rgba(207, 32, 32, 0.10);
                      position: relative;
                    }
                  }
                }

                span {
                  color: var(--Secundarios-Neutro-4, #333);
                  text-align: center;
                  font-family: "Myriad Pro";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;
                }
              }

              .participar-rewards-desc {
                padding: 32px 20px 67px;
                background: var(--Secundarios-Neutro-9, #FFF);
                border-radius: 0 0 20px 20px;
                overflow: hidden;
                
                .slick-slider {
                  .slick-list {
                    overflow: visible;
                    .slick-track {
                  
                      .slick-slide {
                        width: 304px !important;
                        min-height: 430px;

                        .participar-rewards__items--item {
                          border-radius: 20px;
                          border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                          background: var(--Secundarios-Neutro-9, #FFF);
                          box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                          max-width: 280px;
                          min-height: 410px;

                          img {
                            border-radius: 20px 20px 0px 0px;
                            width: 100%;
                          }

                          .participar-rewards__items--item--desc {
                            padding: 16px;
                            text-align: left;

                            span {
                              color: var(--Text-High-Emphasis, #333);
                              font-family: "Myriad Pro";
                              font-size: 20px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: normal;

                              strong {
                                color: var(--primario-primario-7-base, #ED1C27);
                                font-family: "Myriad Pro Black";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 900;
                                line-height: normal;
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .slick-dots {
                    bottom: -24px;
  
                    li {
                      background-color: #707070;
                      border-radius: 50px;
                      height: 8px;
                      margin: 0 4px;
                      position: relative;
                      transition: all .3scubic-bezier(.77,0,.175,1);
                      width: 8px;
  
                      button {
                        width: 100%;
                        height: 100%;
  
                        &::before {
                          display: none;
                        }
                      }
  
                      &.slick-active {
                        background-color: #333;
                        width: 20px;
                        height: 8px;
                        border-radius: 20px;
                      }
                    }
                  }
  
                  .slick-arrow {
                    top: auto;
                    bottom: -28px;
                    transform: translate(0px, 0px);
                    margin: auto;
                    background-position: center center !important;
                    background-repeat: no-repeat !important;
                    background-size: 100% !important;
                    opacity: 1;
                    z-index: 2;
              
                    &:before {
                      display: none;
                    }
              
                    &.slick-prev {
                      right: 80px;
                      background-image: url('../../../public/assets/images/arrow-L.png');
                      -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                      -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                      -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                      transition: all .3s cubic-bezier(.77,0,.175,1);
                    }
              
                    &.slick-next {
                      left: 80px;
                      background-image: url('../../../public/assets/images/arrow-L.png');
                      transform: rotate(180deg);
                      -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                      -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                      -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                      transition: all .3s cubic-bezier(.77,0,.175,1);
                    }
                  }
                }
              }
            }
          }
        }

        .participar-button-container {
          max-width: 326px;
          margin: 32px auto 0;
          padding-bottom: 32px;
        }
      }

      @media only screen and (min-width: 992px) {
        padding: 80px 0 0;

        & > img {
          width: 100%;
          height: auto;
        }

        .mc-login__header {
          position: relative;
          display: block;
          box-sizing: border-box;
          padding: 0 18px;
          width: 1100px;
          max-width: 90%;
          margin: 0 auto;
          z-index: 2;
          background: transparent !important;
          text-align: right;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0px;
          bottom: 0px;
          height: 200px;
          transform: translate(0px, -105%);

          .mc-app-container {
            width: 400px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: auto;
            bottom: auto;
            height: auto;
            padding: 0px;

            .mc-app-rely {
              width: 400px;
              text-align: left;
              margin: 0 auto;

              img {
                width: 336px;
              }
              h1 {
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                margin-top: 0px;
                color: #2D2D2D;

                br {
                  display: none;
                }
              }
            }
          }
        }

        .mc-app-container {
          padding-bottom: 0;
        }

        .tabs-container {
          padding-top: 32px;

          .section-tab-login {
            width: fit-content;
            max-width: fit-content;

            .tab {
              gap: 13.019px;

              .tab-item {
                &:hover {
                  background: var(--Primario-Primario-3, #FFC5C8);
                  border-radius: 71.014px;
                }

                &.active {
                  &:hover {
                    background: #fff;
                    border-radius: 71.014px;
                    border: 2px solid var(--Primario-Primario-8, #C70E18);
                    box-shadow: 0px 85px 24px 0px rgba(235, 15, 15, 0.00), 0px 54px 22px 0px rgba(235, 15, 15, 0.01), 0px 31px 18px 0px rgba(235, 15, 15, 0.05), 0px 14px 14px 0px rgba(235, 15, 15, 0.09), 0px 3px 7px 0px rgba(235, 15, 15, 0.10);
                  }
                }

                h3 {
                  align-items: center;
                  display: flex;
                  color: var(--Text-High-Emphasis, #333);
                  text-align: center;
                  font-family: "Myriad Pro";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                  gap: 5px;
                  justify-content: center;
                  margin: 0;
                }
              }
            }
          }
        }

        .participar-container {
          padding: 0;

          .participar-explanation {
            flex-direction: row;
            gap: 24px;
            padding: 0 0 32px 100px;

            .participar-steps-container {
              padding: 24px 32px 70px;
              text-align: start;
              border-radius: 20px 0px 0px 20px;

              h4 {
                color: var(--Secundarios-Neutro-4, #333);
                font-family: "Myriad Pro";
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                margin: 0;
                margin-bottom: 12px;
              }

              p {
                color: var(--Secundarios-Neutro-4, #333);
                font-family: "Myriad Pro";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                margin: 0;
                margin-bottom: 24px;
              }

              .participar-steps__items--item {
                border-radius: 18px 18px 18.778px 18.778px;
                border: 1.174px solid var(--Secundarios-Neutro-8, #F0F0F0);
                background: var(--Secundarios-Neutro-9, #FFF);
                /* Shadow card negra */
                box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                max-width: 326px;
                min-height: 191px;

                .participar-steps__items--item--title {
                  border-radius: 18px 18px 0px 0px;
                  border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                  background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                  padding: 14px 20px 12px 20px;

                  .svg-type1 {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }

                  .svg-type3 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                  }

                  .svg-type2 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                  }

                  h6 {
                    align-items: center;
                    display: flex;
                    color: var(--Text-High-Emphasis, #333);
                    font-family: "Myriad Pro";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    gap: 10px;
                    margin: 0;
                  }
                }

                .participar-steps__items--item--desc {
                  padding: 16px 35px 16px 20px;

                  p {
                    color: var(--Text-Medium-Emphasis, #707070);
                    font-family: "Myriad Pro";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;

                    strong {
                      color: var(--Text-High-Emphasis, #333);
                      font-family: "Myriad Pro";
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 22px;
                    }
                  }
                }
              }

              .slick-slider {
                margin: 0;
                .slick-list {
                  overflow: visible;

                  .slick-track {
                    display: block;
                    max-width: max-content;
  
                    .slick-slide {
                      width: 342px !important;
                    }
                  }
                }

                .slick-dots {
                  bottom: -38px;
                  left: 30px;
                  width: auto;
                  text-align: start;

                  li {
                    background-color: #707070;
                    border-radius: 50px;
                    height: 8px;
                    margin: 0 4px;
                    position: relative;
                    transition: all .3scubic-bezier(.77,0,.175,1);
                    width: 8px;

                    button {
                      width: 100%;
                      height: 100%;

                      &::before {
                        display: none;
                      }
                    }

                    &.slick-active {
                      background-color: #333;
                      width: 20px;
                      height: 8px;
                      border-radius: 20px;
                    }
                  }
                }

                .slick-arrow {
                  top: auto;
                  bottom: -42px;
                  transform: translate(0px, 0px);
                  left: 0px;
                  right: 0px;
                  margin: auto;
                  background-position: center center !important;
                  background-repeat: no-repeat !important;
                  background-size: 100% !important;
                  opacity: 1;
                  z-index: 2;
            
                  &:before {
                    display: none;
                  }
            
                  &.slick-prev {
                    left: 0;
                    right: auto;
                    background-image: url('../../../public/assets/images/arrow-L.png');
                    -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                    -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                    -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                    transition: all .3s cubic-bezier(.77,0,.175,1);
                  }
            
                  &.slick-next {
                    right: auto;
                    left: 84px;
                    background-image: url('../../../public/assets/images/arrow-L.png');
                    transform: rotate(180deg);
                    -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
                    -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
                    -o-transition: all .3s cubic-bezier(.77,0,.175,1);
                    transition: all .3s cubic-bezier(.77,0,.175,1);
                  }
                }
              }
            }
          }

          .participar-rewards {

            .mc-app-container {
              text-align: center;

              .participar-rewards-container {
                border-radius: 20px;
                background: var(--Secundarios-Neutro-9, #FFF);
                /* Shadow card negra */
                box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                max-width: 944px;
                margin: 0 auto;

                .participar-rewards-title {
                  border-radius: 20px 20px 0px 0px;
                  border-bottom: 2px solid var(--primario-primario-7-base, #ED1C27);
                  background: var(--White-gradient, linear-gradient(180deg, #FFF 0%, #E3E3E3 100%));
                  padding: 16px 20px;
                  
                  svg {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                  }

                  h4 {
                    align-items: center;
                    display: flex;
                    color: var(--Secundarios-Neutro-4, #333);
                    font-family: "Myriad Pro";
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32px;
                    gap: 10px;
                    margin: 0;
                    justify-content: center;
                    margin-bottom: 16px;
                  }

                  span {
                    color: var(--Secundarios-Neutro-4, #333);
                    text-align: center;
                    font-family: "Myriad Pro";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                  }
                }

                .participar-rewards-desc{
                  padding: 32px 20px 35px;
                  background: var(--Secundarios-Neutro-9, #FFF);
                  border-radius: 0 0 20px 20px;
                  
                  .slick-slider {
                    .slick-list {
                      .slick-track {
                        justify-content: center;
                        display: flex;
                        gap: 24px;
                    
                        .slick-slide {
                          width: 304px !important;
                          min-height: 430px;

                          .participar-rewards__items--item {
                            border-radius: 20px;
                            border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
                            background: var(--Secundarios-Neutro-9, #FFF);
                            box-shadow: 0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 90px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
                            max-width: 280px;
                            min-height: 437px;

                            img {
                              border-radius: 20px 20px 0px 0px;
                              width: 100%;
                            }

                            .participar-rewards__items--item--desc {
                              padding: 16px;

                              span {
                                color: var(--Text-High-Emphasis, #333);
                                font-family: "Myriad Pro";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;

                                strong {
                                  color: var(--primario-primario-7-base, #ED1C27);
                                  font-family: "Myriad Pro";
                                  font-size: 20px;
                                  font-style: normal;
                                  font-weight: 900;
                                  line-height: normal;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .participar-button-container {
            max-width: 326px;
            margin: 32px auto 0;
            padding-bottom: 32px;
          }
        }

        .mc-app-container {
          text-align: right;
          position: relative;
          padding-top: 32px;

          .login-form-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 60px;
            padding-bottom: 32px;
          }

          .mc-login {
            display: inline-block;
            vertical-align: top;

            .mc-app-container {
              text-align: left;
              position: relative;
              left: 0;
              right: 0;
              margin: auto;
              top: 0%;
              bottom: 0px;
              padding-top: 0;

              .mc-login__form {
                margin-right: 0px;
              }
            }
          }
        }
      }

      @media only screen and (min-width: 1400px) {
        .mc-login__header {
          .mc-app-container {
            .mc-app-rely {
              img {
                width: 350px;
              }
            }
          }
        }
      }
    }

    .login-info-card-container {
      padding: 32px 0;
      background: #fff;

      .login-info-card {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        align-self: stretch;
      }

      .login-info-card-text-container {
        max-width: 355px;
        text-align: center;

        .login-info-card-text-bold {
          color: var(--Text-High-Emphasis, #333);
          text-align: center;
          font-family: "Myriad Pro Black";
          font-size: 24px;
          font-style: normal;
          font-weight: 900;
          line-height: 28px; /* 116.667% */
        }

        .login-info-card-text {
          color: var(--Text-Medium-Emphasis, #707070);
          font-family: "Myriad Pro";
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }

      .login-info-card-image {
        width: 300px;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        img {
          max-width: 177px;
        }
      }

      @media only screen and (max-width: 992px) {
        padding: 56px 0;

        .login-info-card {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        }
      }
    }

    .mc-section {
      margin-top: 30px;

      .mc-cardbox-container {
        background-color: transparent;
        img {
          background-color: transparent !important;
        }
        h2 {
          margin: 0px 0px !important;
          padding: 20px 0px !important;
        }
        h2, .desc-paraTodos {
          width: 100%;
          background-color: rgba(230, 230, 230, 0.6980392157);
          max-width: 100%;
          box-sizing: border-box;
        }
      }

      @media only screen and (min-width: 992px) {
        position: relative;
        display: block;
        width: 1100px;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0 18px;
        margin: -190px auto 0 auto;
        z-index: 2;
        // display: none !important;

        .mc-cardbox-container {
          height: auto;
        }
      }
      @media only screen and (max-width: 992px) {
        margin-bottom: 0px !important;
      }
    }
    
    .mc-slider-rotator {
      padding-right: 0px;

      .slick-slider {
        padding: 0 0px;
        .slick-list {
          .mc-cardbox-container {
            margin: 5px;
          }
        }
      }
    }
  }
  // &.mc-page-awards{
  //   .mc-page-content{
  //     min-height: 600px;
  //   }
  // }
}