.mc-page {
    &.not-found {
        background: #fff;

        .not-found-container {
            padding: 80px 0;
            display: flex;
            gap: 100px;
            justify-content: center;
            align-items: center;

            .number-container {
                .title {
                    h1 {
                        color: var(--Text-High-Emphasis, #333);
                        font-family: "Myriad Pro Black";
                        font-size: 58.817px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 58.817px; /* 100% */
                        margin: 0;

                        .mc-icon {
                            position: absolute;
                            top: 6px;
                            left: 212px;
                        }
                    }
                }

                .number {
                    color: var(--primario-primario-7-base, #ED1C27);
                    font-family: "Myriad Pro Black";
                    font-size: 150.718px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 150.718px; /* 100% */
                }
            }

            .text-container {
                h2 {
                    color: var(--primario-primario-7-base, #ED1C27);
                    font-family: "Myriad Pro Semibold";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; /* 116.667% */
                    margin: 0;
                }

                p {
                    color: var(--Contenidos, #131112);
                    font-family: "Myriad Pro";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 137.5% */
                    margin: 16px 0;
                    max-width: 365px;

                    @media only screen and (max-width: 992px) {
                        margin: 16px auto;
                    }
                }

                .button-container {
                    max-width: 80%;

                    @media only screen and (max-width: 992px) {
                        margin: auto;
                    }
                }
            }

            @media only screen and (max-width: 992px) {
                flex-direction: column;
                gap: 16px;
                text-align: center;
            }
        }
    }
}

// .mc-header-container__menu {
//     display: flex;
//     flex-direction: column-reverse;
//     height: auto;
//     margin-top: 24px;
    
//     .mc-header-container__title--container {
//         max-width: 332px;
//         margin: 24px auto;
//         text-align: center;

//         .mc-header-container__title {
//         color: var(--Text-High-Emphasis, #333);
//         font-family: "Myriad Pro Bold";
//         font-size: 32px;
//         font-style: normal;
//         font-weight: 900;
//         line-height: 32px;
//         }

//         .mc-header-container__title--red {
//         color: var(--primario-primario-7-base, #ED1C27);
//         font-family: "Myriad Pro Bold";
//         font-size: 32px;
//         font-style: normal;
//         font-weight: 900;
//         line-height: 32px; /* 100% */
//         }

//         @media only screen and (min-width: 992px) {
//             position: absolute;
//             right: 73px;
//             top: 26.93px;
//             max-width: fit-content;
//             margin: auto;
    
//             .mc-header-container__title {
//                 color: var(--Text-High-Emphasis, #333);
//                 font-family: "Myriad Pro Bold";
//                 font-size: 32px;
//                 font-style: normal;
//                 font-weight: 900;
//                 line-height: 32px;
//             }
    
//             .mc-header-container__title--red {
//                 color: var(--primario-primario-7-base, #ED1C27);
//                 font-family: "Myriad Pro Bold";
//                 font-size: 32px;
//                 font-style: normal;
//                 font-weight: 900;
//                 line-height: 32px; /* 100% */
//             }
            
//         }
//     }
// }