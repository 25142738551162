$base: #263238;
$lite:  #FFF;
$brand: #FF3D00;
$size: 48px;

*:after, *:before {
      box-sizing: border-box;
    }

@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
@keyframes rotationBack {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(-360deg) }
}
@keyframes rotationBreak {
  0% { transform: rotate(0) }
  25% { transform: rotate(90deg)}
  50% { transform: rotate(180deg) }
  75% { transform: rotate(270deg)}
  100% { transform: rotate(360deg) }
}

@keyframes zeroRotation {
  0% { transform: scale(1) rotate(0deg) }
  100% { transform: scale(0) rotate(360deg)}
}

@keyframes shadowExpandX {
  0% { 
    box-shadow: 0 0, 0 0;  
    color: rgba($lite, 0.2)
  }
  100% { 
    box-shadow: -$size/2 0, $size/2 0; 
    color: rgba($lite, .8) 
  }
}

@keyframes shadowPulse {
  33% {   
    background: $lite;
    box-shadow:  -$size/2 0 $brand, $size/2 0 $lite;
  } 
  
  66% {   
    background: $brand;
    box-shadow:  -$size/2 0 $lite, $size/2 0 $lite;
  }  
  100% {   
    background: $lite;
    box-shadow:  -$size/2 0 $lite, $size/2 0 $brand;
  }
  
}

@keyframes shadowRolling {
  0% {  box-shadow: 0px 0 rgba($lite, 0), 
                    0px 0 rgba($lite, 0), 
                    0px 0 rgba($lite, 0), 
                    0px 0 rgba($lite, 0); 
  }
  12% {  box-shadow: 100px 0 rgba($lite, 1),
                      0px 0 rgba($lite, 0),
                      0px 0 rgba($lite, 0), 
                      0px 0 rgba($lite, 0); 
  }
  25% {  box-shadow: 110px 0 rgba($lite, 1), 
                      100px 0 rgba($lite, 1), 
                      0px 0 rgba($lite, 0), 
                      0px 0 rgba($lite, 0); 
  }
  36% {  box-shadow: 120px 0 rgba($lite, 1), 
                      110px 0 rgba($lite, 1), 
                      100px 0 rgba($lite, 1), 
                      0px 0 rgba($lite, 0); 
  }
  50% {  box-shadow: 130px 0 rgba($lite, 1), 
                      120px 0 rgba($lite, 1), 
                      110px 0 rgba($lite, 1), 
                      100px 0 rgba($lite, 1); 
  }
  62% {  box-shadow: 200px 0 rgba($lite, 0), 
                      130px 0 rgba($lite, 1), 
                      120px 0 rgba($lite, 1), 
                      110px 0 rgba($lite, 1); 
  }
  75% {  box-shadow: 200px 0 rgba($lite, 0), 
                      200px 0 rgba($lite, 0), 
                      130px 0 rgba($lite, 1), 
                      120px 0 rgba($lite, 1); 
  }
  87% {  box-shadow: 200px 0 rgba($lite, 0),
                      200px 0 rgba($lite, 0), 
                      200px 0 rgba($lite, 0), 
                      130px 0 rgba($lite, 1); 
  }
  100% {  box-shadow: 200px 0 rgba($lite, 0), 
                      200px 0 rgba($lite, 0), 
                      200px 0 rgba($lite, 0), 
                      200px 0 rgba($lite, 0); 
  }
  
}

@keyframes animloader14 {
  0% { transform: scale(0); opacity: 1;}
  100% { transform: scale(1); opacity: 0;}
}


@keyframes scale50 {
  0%, 100% { transform: scale(0)}
  50% { transform: scale(1)}
}

@keyframes scaleOut {
  0% { transform: scale(0);}
  100% { transform: scale(1)}
}

.mc-preloader-container {
  text-align: center;

  img.invert {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  &__fullscreen,
  &__ok,
  &__error {
    position: fixed;
    z-index: 999;
    background-color: rgba(255,255,255,0.88);
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  &__fullscreen {
    background: linear-gradient(0deg, #FFF -2.01%, #E3E3E3 76.57%);

    p {
      color: var(--Text-High-Emphasis, #333);
      text-align: center;
      /* Title/h3-Semibold */
      font-family: "Myriad Pro Semibold";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 112.5% */
      margin: 24px auto 0;
      bottom: 30px;

      @media only screen and (max-width: 992px) {
        max-width: 200px;
      }
    }

    section {
      margin-top: -6%;

      @media only screen and (max-width: 992px) {
        margin-top: -30%;
      }

      .loader-11 {
        width : $size;
        height: $size;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        border: 3px solid;
        border-color: $brand $brand transparent transparent;
        animation: rotation 1s linear infinite;
        &:after , &:before{
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          border: 3px solid;
          border-color: transparent transparent $base $base;
          width:  $size - 8px;
          height:  $size - 8px;
          border-radius: 50%;
          animation: rotationBack 0.5s linear infinite;
          transform-origin: center center;
        }
        &:before{
          width:  $size - 16px;
          height:  $size - 16px;
          border-color:$brand $brand transparent transparent;
          animation: rotation 1.5s linear infinite;
        }
      }

      
    }

    .rojo {
      color: var(--primario-primario-7-base, #ED1C27);
      text-align: center;
      /* Title/h4-Black */
      font-family: "Myriad Pro Black";
      font-size: 28px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px; /* 114.286% */

      @media only screen and (max-width: 992px) {
        max-width: 270px;
        margin: 16px auto;
      }
    }

    .negro {
      color: var(--Text-High-Emphasis, #333);
      text-align: center;
      /* P1/p-Regular */
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      max-width: 495px;
      margin: 16px auto;

      strong {
        /* P1/p-Semibold */
        font-family: "Myriad Pro Semibold";
        font-weight: 600;
      }

      @media only screen and (max-width: 992px) {
        max-width: 270px;
        margin: 16px auto;
      }
    }

    .login-button-container {
      max-width: 300px;
      margin: auto;
    }

    .gif {
      width: 110px;
      margin: 16px auto 0;
    }

    img {
      margin: 24px auto 0;

      &.loading-gif {
        width: 500px;
        margin: 0;
        bottom: 30px;

        @media only screen and (max-width: 992px) {
          width: 310px;
        }
      }
    }
  }

  &__section {
    img {
      margin: 24px auto 0;

      &.loading-gif {
        width: 500px;
        margin: 0;
        top: 18px;

        @media only screen and (max-width: 992px) {
          width: 310px;
        }
      }
    }
  }


  &__ok {
    .success-animation { margin:150px auto;}
    .checkmark {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #4bb71b;
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px #4bb71b;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
      position:relative;
      top: 5px;
      right: 5px;
       margin: 0 auto;
    }
    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #4bb71b;
      fill: #fff;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
     
    }
    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
    
    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }
    
    @keyframes scale {
      0%, 100% {
        transform: none;
      }
      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }
    
    @keyframes fill {
      100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
      }
    }
  }

  &__error {
    .ui-error {
      width: 100px;
      height: 100px;
      margin: 0px auto;
    }
    
    @keyframes ani-success-path {
      0% {stroke-dashoffset: 62px;}
      65% {stroke-dashoffset: -5px;}
      84%{stroke-dashoffset: 4px;}
      100%{stroke-dashoffset: -2px;}
    }
    
    .ui-error{
      &-circle{
        stroke-dasharray:260.75219024795285px, 260.75219024795285px;
        stroke-dashoffset: 260.75219024795285px;
        animation: ani-error-circle 1.2s linear;
      }
      &-line1{
        stroke-dasharray: 54px 55px;
        stroke-dashoffset: 55px;
        stroke-linecap: round;
        animation: ani-error-line .15s 1.2s linear both;
      }
      &-line2{
        stroke-dasharray: 54px 55px;
        stroke-dashoffset: 55px;
        stroke-linecap: round;
        animation: ani-error-line .2s .9s linear both;
      }
    }
    
    @keyframes ani-error-line{
      to { stroke-dashoffset: 0; }
    }
    
     @keyframes ani-error-circle {
        0% {
            stroke-dasharray: 0, 260.75219024795285px;
            stroke-dashoffset: 0;
        }
        35% {
            stroke-dasharray: 120px, 120px;
            stroke-dashoffset: -120px;
        }
        70% {
            stroke-dasharray: 0, 260.75219024795285px;
            stroke-dashoffset: -260.75219024795285px;
        }
        100% {
            stroke-dasharray: 260.75219024795285px, 0;
            stroke-dashoffset: -260.75219024795285px;
        }
    }
  }
}