.mc-cardbox-container {
  position: relative;
  background-color: rgb(245, 245, 245);
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  margin: 5px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);

  & > img {
    width: 100px;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  & > h2 {
    font-size: 20px;
    color: $primaryColor;
    text-align: center;
  }

  & > div {
    font-size: 15px;
    color: $colorText;
    text-align: center;
  }
}